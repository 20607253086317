<script>
    import lottie from 'lottie-web'

    export default {
        props: {
            iControl: Object
        },
        mounted() {
            lottie.loadAnimation({
                container: this.$refs.iconAnimated,
                loop: true,
                autoplay: true,
                animationData: this.iControl.iconAnimated
            })
            if (/Windows/.test(navigator.userAgent)) {
                this.$refs.menu.select(0)
            }
            if (/Mac OS/.test(navigator.userAgent)) {
                this.$refs.menu.select(1)
            }
            if (/Linux/.test(navigator.userAgent)) {
                this.$refs.menu.select(2)
            }
        },
    }
</script>

<template>

    <!-- CONTAINER iControl -->
    <div class="ic-container">

		<ic-particles-bg />

        <!-- MAIN -->
		<main>
            <div class="ic-liquid">
				<div>
                    <div class="icon" ref="iconAnimated"></div>
					<h1 class="title">Incompatible Browser</h1>
					<h2 class="subtitle">Sorry... Your browser is not compatible with iControl 3</h2>

                    <ic-menu ref="menu">
                        <ic-menu-item title="Windows" titleIcon="Windows">
                            <div class="mt-10">
                                <a href="https://www.google.com/chrome/">
                                    <ic-button variant="default" >
                                        Download Chrome
                                    </ic-button>
                                </a>
                            </div>
                        </ic-menu-item>
                        <ic-menu-item title="macOS" titleIcon="Apple">
                            <div class="mt-10">
                                <ic-button class="cursor-default">
                                    Use Safari
                                </ic-button>
                            </div>
                        </ic-menu-item>
                        <ic-menu-item title="Linux" titleIcon="Linux">
                            <div class="mt-10">
                                <a href="https://www.chromium.org/getting-involved/download-chromium">
                                    <ic-button>
                                        Download Chromium
                                    </ic-button>
                                </a>
                            </div>
                        </ic-menu-item>
                    </ic-menu>

				</div>
			</div>
		</main>

        <!-- FOOTER -->
        <footer>
            <ic-watermark />
        </footer>

	</div>

</template>

<style scoped>

	main { @apply pt-0 bg-transparent items-center; }
    main > div { @apply relative z-20 h-screen flex items-center text-center justify-center h-screen; }
	main .icon { @apply h-24 w-24 m-auto; }
	main .title { @apply font-bold text-4xl text-white mb-6 text-center ; }
	main .subtitle { @apply font-medium text-2xl text-white mb-4 text-center ; }
	main .back { @apply relative inline-block text-sm text-white bg-primary px-4 py-2 text-base rounded focus:outline-none justify-center items-center cursor-pointer text-left bottom-0 items-center flex items-center mt-16 space-x-2; }

    :deep() li a            { @apply !text-gray-100;  }
    :deep() li a:hover      { @apply !text-primary;  }
    :deep() li a.current    { @apply !text-primary; }

    :deep() button          { @apply !text-gray-100 !border-gray-100; }

</style>
