<template>

    <input type="hidden" v-model="this.value">

</template>

<script>
    import Field from './Field.vue'
    export default {
        mixins: [Field],
    }
</script>
