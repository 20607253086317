<script>
    export default {
        data() { return {
            options: {
                'background': {},
                'particles': {
                    'number': { 'value': 75, 'density': { 'enable': true, 'value_area': 1000 } },
                    'color': { 'value': '#919b00' },
                    'shape': {
                        'type': 'circle',
                        'stroke': { 'width': 0, 'color': '#000000' },
                        'polygon': { 'nb_sides': 5 },
                    },
                    'opacity': { 'value': 0.5, },
                    'size': { 'value': 3, 'random': true, },
                    'line_linked': {
                        'enable': true,
                        'distance': 150,
                        'color': '#919b00',
                        'opacity': 1,
                        'width': 1
                    },
                    'move': {
                        'enable': true,
                        'speed': 3,
                        'direction': 'none',
                        'random': false,
                        'straight': false,
                        'out_mode': 'bounce',
                        'bounce': false,
                    }
                },
                'interactivity': {
                    'detect_on': 'window',
                    'events': {
                        'onhover': { 'enable': true, 'mode': 'bubble' },
                        'onclick': { 'enable': false, },
                        'resize': true
                    },
                    'modes': {
                        'bubble': {
                            'distance': 400,
                            'size': 3.996003996003996,
                            'duration': 2,
                            'opacity': 8,
                            'speed': 3
                        },
                    }
                },
                'retina_detect': true
            }
        }},
    }
</script>

<template>

    <particles id="particles" class="particles" :options="this.options" />

</template>

<style scoped>

    .particles {
        @apply bg-[#575757] fixed z-0 top-0 bottom-0 left-0 right-0
    }

</style>
