<template>

    <div class="ic-container">

        <ic-particles-bg />

        <ic-screen-size-guard v-if="['login', ].includes(this.page)"/>

        <!-- MAIN -->
		<main>
            <div class="ic-liquid">

                    <!-- LEFT -->
                    <div class="welcome">
                        <a href="/">
                            <div class="icon" ref="iconAnimated"></div>
                        </a>
                        <h1>iControl <span>{{ this.iControl.version }}</span></h1>
                        <h2>{{ this.iControl.name }}</h2>
                        <p class="text-justify prose">
                            Welcome to iControl 3 web dashboard, your portal to the iControl service constellation.
                        </p>
                        <p>Powered by Digitalblend. </p>
                    </div>

                    <!-- CARD -->
                    <div class="card">
                        <!-- LOGIN -->
                        <div v-if="this.page == 'login'">
                            <form @submit.prevent="this.handleLogin()">
                                <h2>You have an account ?</h2>
                                <h3>Please <span>Login</span></h3>
                                <input v-model="this.login.credentials.email" type="email" required autocomplete="email" placeholder="Email">
                                <input v-model="this.login.credentials.password" type="password" required autocomplete="current-password" placeholder="Password">
                                <small class="errors" v-if="this.login.error">{{ this.login.errorTxt }}</small>
                                <button class="submit" type="submit">
                                    <ic-icons name="Login" /><span>Login</span>
                                </button>
                                <router-link :to="{ name: 'ForgottenPassword', }">Forgot password?</router-link>
                                <router-link class="link" :to="{ name: 'Register', }">
                                    <span>No account yet ? Register here</span>
                                </router-link>
                            </form>
                        </div>

                        <!-- REGISTER -->
                        <div v-if="this.page == 'register'">
                            <form @submit.prevent="this.handleRegister()">
                                <h2>You don't have an account yet ?</h2>
                                <h3> Please <span>Sign up</span></h3>
                                <input v-model="this.register.user.email" type="email" required autocomplete="email" placeholder="Email">
                                <input v-model="this.register.user.password" type="password" required placeholder="Password">
                                <input v-model="this.register.user.passwordConfirm" type="password" required placeholder="Confirm password">
                                <small class="errors" v-if="this.register.error">{{ this.register.errorTxt }}</small>
                                <button class="submit" type="submit">
                                    <ic-icons name="UserAdd" /><span>Register</span>
                                </button>
                                <router-link class="link" :to="{ name: 'Login', }">
                                    <span>Already have an account ? Login here</span>
                                </router-link>
                            </form>
                        </div>

                        <!-- ACTIVATE -->
                        <div v-if="this.page == 'activate'">
                            <div v-if="this.activate.app !== undefined"
                                class="flex flex-col items-center pb-5"
                            >
                                <img class="rounded-lg" :src="this.activate.app.appIcon" width="128"/>
                                <h2 class="text-default py-2">{{ this.activate.app.name }}</h2>
                                <div class="flex justify-between">
                                    <a v-if="this.activate.app.appAppStoreUrl" :href="this.activate.app.appAppStoreUrl"><ic-icons name="AppStore" size="xxl"/></a>
                                    <span v-if="this.activate.app.appAppStoreUrl && this.activate.app.appPlayStoreUrl" class="px-2"></span>
                                    <a v-if="this.activate.app.appPlayStoreUrl" :href="this.activate.app.appPlayStoreUrl"><ic-icons name="PlayStore" size="xxl"/></a>
                                </div>
                            </div>
                            <form @submit.prevent="this.handleActivate()">
                                <h2>Activate your account</h2>
                                <h3><span v-if="this.$route.query.email !== undefined">{{ this.activate.email }}</span></h3>
                                <input v-if="this.$route.query.password !== undefined"
                                    v-model="this.activate.password"
                                    type="password" required placeholder="Password"
                                >
                                <input v-if="this.$route.query.password !== undefined"
                                    v-model="this.activate.confirm"
                                    type="password" required placeholder="Confirm Password"
                                >
                                <small class="errors" v-if="this.activate.error">{{ this.activate.errorTxt }}</small>
                                <button class="submit" type="submit">
                                    <ic-icons name="Check" /><span>Activate</span>
                                </button>
                            </form>
                        </div>

                        <!-- ACTIVATED -->
                        <div v-if="this.page == 'activated'">
                            <div v-if="this.activate.app !== undefined"
                                class="flex flex-col items-center pb-5"
                            >
                                <img class="rounded-lg" :src="this.activate.app.appIcon" width="128"/>
                                <h2 class="text-default py-2">{{ this.activate.app.name }}</h2>
                                <div class="flex justify-between">
                                    <a v-if="this.activate.app.appAppStoreUrl" :href="this.activate.app.appAppStoreUrl"><a name="AppStore" size="xxl"/></a>
                                    <span v-if="this.activate.app.appAppStoreUrl && this.activate.app.appPlayStoreUrl" class="px-2"></span>
                                    <a v-if="this.activate.app.appPlayStoreUrl" :href="this.activate.app.appPlayStoreUrl"><ic-icons name="PlayStore" size="xxl"/></a>
                                </div>
                            </div>
                            <form>
                                <h2>Account activated !</h2>
                                <br>
                                <span class="text-2xl text-info" v-if="this.$route.query.email !== undefined">Welcome <h4><span class="font-bold text-primary">{{ this.activate.email }}</span></h4></span>
                                <router-link :to="{ name: 'Login', }">
                                    <button class="submit">
                                        <ic-icons name="Login" /><span>Login</span>
                                    </button>
                                </router-link>
                            </form>
                        </div>

                        <!-- FORFOTTEN PASSWORD -->
                        <div v-if="this.page == 'forgotten-password'">
                            <form @submit.prevent="this.handleForgottenPassword()">
                                <h2>Forgot your password ?</h2>
                                <h3>Please enter <span>your email</span></h3>
                                <input v-model="this.forgottenPassword.email" type="email" required autocomplete="email" placeholder="Email">
                                <small class="errors" v-if="this.forgottenPassword.error">{{ this.forgottenPassword.errorTxt }}</small>
                                <button class="submit" type="submit">
                                    <ic-icons name="Mail" /><span>Recover</span>
                                </button>
                            </form>
                        </div>

                        <!-- FORFOTTEN PASSWORD PROCESSING -->
                        <div v-if="this.page == 'forgotten-password-processing'">
                            <form>
                                <h2>An email to recover <span>your password</span> has been sent !</h2>
                            </form>
                        </div>

                        <!-- RECOVER PASSWORD -->
                        <div v-if="this.page == 'recover-password'">
                            <form @submit.prevent="this.handleRecoverPassword()">
                                <h2>Recover your password !</h2>
                                <h3>Create a <span>new one</span></h3>
                                <input v-model="this.recoverPassword.password" type="password" required placeholder="Password">
                                <input v-model="this.recoverPassword.confirm" type="password" required placeholder="Confirm Password">
                                <small class="errors" v-if="this.recoverPassword.error">{{ this.recoverPassword.errorTxt }}</small>
                                <button class="submit" type="submit">
                                    <ic-icons name="Check" /><span>Reset password</span>
                                </button>
                            </form>
                        </div>

                        <!-- RECOVERED PASSWORD -->
                        <div v-if="this.page == 'recovered-password'">
                            <form>
                                <h2>Password recovered !</h2>
                                <br>
                                <span class="text-2xl text-info" v-if="this.$route.query.email !== undefined">Welcome back <span class="font-bold text-primary">{{ this.$route.query.email }}</span></span>
                                <router-link :to="{ name: 'Login', }">
                                    <button class="submit">
                                        <ic-icons name="Login" /><span>Login</span>
                                    </button>
                                </router-link>
                            </form>
                        </div>

                        <!-- CANCEL DELETE -->
                        <div v-if="this.page == 'cancel-delete'">
                            <form @submit.prevent="this.handleCancelDelete()">
                                <h2>Cancel delete ?</h2>
                                <h3>Cancel your <span>account deletion</span></h3>
                                <small class="errors" v-if="this.cancelDelete.error">{{ this.cancelDelete.errorTxt }}</small>
                                <button class="submit" type="submit">
                                    <ic-icons name="Rewind" /><span>Cancel</span>
                                </button>
                            </form>
                        </div>

                        <!-- CANCELED DELETE -->
                        <div v-if="this.page == 'canceled-delete'">
                            <form>
                                <h2>Delete Canceled !</h2>
                                <h3>Welcome back <span>{{ $route.query.email }}</span></h3>
                                <router-link :to="{ name: 'Login', }">
                                    <button class="submit">
                                        <ic-icons name="Login" /><span>Login</span>
                                    </button>
                                </router-link>
                            </form>
                        </div>

                    </div>
            </div>
		</main>

	</div>

</template>

<script>
    import lottie from 'lottie-web'

    export default {
        props: {
            iControl: { type: Object, },
            page: { validator: (value) =>
                [
                    'login',
                    'register',
                    'activate',
                    'activated',
                    'forgotten-password',
                    'forgotten-password-processing',
                    'recover-password',
                    'recovered-password',
                    'cancel-delete',
                    'canceled-delete'
                ].includes(value),
            },
        },
        data() { return {
            login: {
                credentials: { email: "", password: ""},
                error: this.$route.query.error !== undefined,
                errorTxt: this.$route.query.error,
            },
            register: {
                user: { email: "", password: "", passwordConfirm: "", },
                error: false,
                errorTxt: undefined,
            },
            activate: {
                email: this.$route.query.email,
                token: this.$route.query.token,
                app: undefined,
                password: undefined,
                confirm: undefined,
                error: false,
                errorTxt: undefined,
            },
            forgottenPassword: {
                email: undefined,
                error: false,
                errorTxt: undefined,
            },
            recoverPassword: {
                password: undefined,
                confirm: undefined,
                error: false,
                errorTxt: undefined,
            },
            cancelDelete: {
                email: this.$route.query.email,
                token: this.$route.query.token,
                error: false,
                errorTxt: undefined,
            }
        }},
        methods: {
            handleLogin() {
                this.login.error = false
                this.login.errorTxt = undefined
                this.$store.dispatch('auth/login', this.login.credentials)
                    .then(() => {
                        if (!this.$store.getters['auth/anyAdmin'] && this.$store.getters['auth/user'].policies.length == 0) {
                            throw "You do not have the required permission to access the web interface."
                        }
                        this.$router.push('/')
                    })
                    .catch(error => {
                        this.$store.dispatch('auth/logout')
                        this.login.error = true
                        this.login.errorTxt = error.response?.data.reason || error.message || error
                    })
            },
            handleRegister() {
                this.register.error = false
                this.register.errorTxt = undefined
                if (this.register.user.password === this.register.user.passwordConfirm) {
                    this.$store.dispatch('auth/register', this.register.user)
                        .then(() => { this.$router.push('/') })
                        .catch(error => {
                            this.$store.dispatch('auth/logout')
                            this.register.error = true
                            this.register.errorTxt = error.response.data.reason || error.message || error
                        })
                } else {
                    this.register.error = true
                    this.register.errorTxt = "Passwords must match"
                }
            },
            handleActivate() {
                this.activate.error = false
                this.activate.errorTxt = undefined
                if (this.activate.password === this.activate.confirm) {
                    this.$store.dispatch('auth/activate', { token: this.$route.query.token, password: this.activate.password })
                        .then(() => this.$router.push({ path: '/activated', query: this.$route.query, }))
                        .catch(error => {
                            this.activate.error = true
                            this.activate.errorTxt = error.response.data.reason || error.message || error
                        })
                } else {
                    this.activate.error = true
                    this.activate.errorTxt = "Passwords must match"
                }
            },
            handleForgottenPassword() {
                this.forgottenPassword.error = false
                this.forgottenPassword.errorTxt = undefined
                this.$store.dispatch('auth/forgottenPassword', { email: this.forgottenPassword.email, })
                    .then(() => this.$router.push('/forgotten-password-processing'))
                    .catch(error => {
                        this.forgottenPassword.error = true
                        this.forgottenPassword.errorTxt = error.response.data.reason || error.message || error
                    })
            },
            handleRecoverPassword() {
                this.recoverPassword.error = false
                this.recoverPassword.errorTxt = undefined
                if (this.recoverPassword.password === this.recoverPassword.confirm) {
                    this.$store.dispatch('auth/recoverPassword', { token: this.$route.query.token, password: this.recoverPassword.password, passwordConfirm: this.recoverPassword.password, })
                        .then(() => this.$router.push('/recovered-password'))
                        .catch(error => {
                            this.recoverPassword.error = true
                            this.recoverPassword.errorTxt = error.response.data.reason || error.message || error
                        })
                } else {
                    this.recoverPassword.error = true
                    this.recoverPassword.errorTxt = "Passwords must match"
                }
            },
            handleCancelDelete() {
                this.cancelDelete.error = false
                this.cancelDelete.errorTxt = undefined
                this.$store.dispatch('auth/cancelDelete', { token: this.cancelDelete.token, email: this.cancelDelete.email })
                    .then(() => this.$router.push({ path: '/canceled-delete', query: this.$route.query, }))
                    .catch(error => {
                        this.cancelDelete.error = true
                        this.cancelDelete.errorTxt = error.response.data.reason || error.message || error
                    })
            }
        },
        mounted() {
            lottie.loadAnimation({
                container: this.$refs.iconAnimated,
                loop: true,
                autoplay: true,
                animationData: this.iControl.iconAnimated
            })
            if (this.$route.query.app !== undefined) {
                this.$api.Model.item('apps/', this.$route.query.app)
                    .then(item => { this.activate.app = item; })
            }
        },
    }
</script>

<style scoped>

    .ic-liquid                  { @apply max-w-7xl mx-auto overflow-scroll; }

    main                        { @apply relative h-screen w-screen backdrop-opacity-50; }

    main .welcome               { @apply p-5 w-full flex flex-col justify-center items-center; }
    @screen lg {
        main .welcome           { @apply absolute top-0 bottom-0 left-0 h-full w-1/2 items-start; }
    }
    main .welcome .icon         { @apply h-24 w-24; }
    main .welcome h1            { @apply font-bold text-4xl text-white mb-6; }
    main .welcome h1 span       { @apply text-xl; }
    main .welcome h2            { @apply font-medium text-2xl text-white mb-4; }
    main .welcome p             { @apply font-normal text-base text-white w-1/2; }

    main .card                  { @apply p-5 w-full; }
    @screen lg {
        main .card              { @apply absolute flex flex-col justify-center items-center top-0 bottom-0 right-0 w-1/2 ; }
    }
    main .card div              { @apply w-full max-w-full; } /* considerate as wrapper */
    main .card form             { @apply w-full min-w-full max-w-full p-10 rounded-2xl shadow-xl bg-white text-left; }
    main .card form h2          { @apply font-bold text-4xl text-info ; }
    main .card form h2 span     { @apply text-primary;}
    main .card form h3          { @apply font-bold text-4xl text-info mb-8 break-words; }
    
    main .card form h3 span     { @apply text-primary; }
    
    main .card form h4 span     { @apply  text-primary break-words font-bold; }

    main .card form input       { @apply relative block w-full px-0 py-2 appearance-none border-b border-info bg-transparent text-gray-700 mb-4 focus:outline-none; }

    main .card form .errors     { @apply flex justify-center items-center  text-xs text-danger font-light; }

    main .card form .submit     { @apply flex justify-center items-center px-4 py-2 mt-6 rounded bg-primary font-medium text-base text-white cursor-pointer; }
    main .card form .submit svg { @apply h-6 w-6 mr-2; }

    main .card form a           { @apply flex mt-5 text-sm text-gray-500 hover:text-primary; }

    main .card form a.link      { @apply flex justify-center items-center py-2 mt-16 rounded bg-primary text-white cursor-pointer focus:outline-none; }

</style>
