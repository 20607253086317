<template>

    <div class="relative">
        <button class="m-1 px-1 py-0.5" @click.stop="this.show = !this.show">
            <slot name="button">Menu</slot>
        </button>
        <div v-click-outside="this.clickOutside" v-show="this.show">
            <div class="content bg-body shadow-xl rounded-lg" v-show="this.show">
                <slot></slot>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        data() { return {
            show: false,
            items: [],
        }},
        methods: {
            clickOutside(event) {
                event
                if (this.show) {
                    this.show = false
                }
            }
        },
    }
</script>

<style scoped>

    .content { @apply absolute z-50 p-1; border-width: 1px; border-color: var(--color-body-accent)}
    .content:deep() * { @apply rounded my-1; }
    .content:deep() *:hover { background-color: var(--color-body-accent) }

</style>
