<template>

    <button type="button" @click="this.value = !this.value" class="flex-shrink-0 hover:none group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none">
        <span
            class="absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"
            :class="this.value ? 'bg-primary' : 'bg-info'"
        ></span>
        <span
            class="absolute left-0 inline-block h-5 w-5 rounded-full bg-white border shadow transform ring-0 transition-transform ease-in-out duration-200"
            :class="this.value ? 'translate-x-5' : 'translate-x-0'"
        ></span>
    </button>
    <small :class="{ error: this.error !== undefined }">{{ error }}</small>

</template>

<script>
    import Field from './Field.vue'
    export default {
        mixins: [Field],
        props: {
            modelValue: { default: false }  // default value => possible output false/true => can't be undefined
        }
    }
</script>
