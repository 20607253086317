<script>
    export default {
        props: {
            icontrol: { type: Object, required: true, },
        },
        data() { return {
            loaded: false,
            namespaces: []
        }},
        computed: {
            sortedNamespace() {
                return this.namespaces.slice().sort((left, right) => {
                    let fa = left.name.toLowerCase(), fb = right.name.toLowerCase();
                    if (fa < fb) {
                        return -1
                    }
                    if (fa > fb) {
                        return 1
                    }
                    return 0
                })
            }
        },
        mounted() {
            Promise.all(this.icontrol.namespaces.map(async (namespace, index) => {
                await this.$api.Namespace.Infos(namespace.path + '/')
                    .then(() => { this.namespaces.splice(index, 0, namespace) })
                    .catch(() => { })
            }))
            .then(() => {
                this.loaded = true
            })
        },
    }
</script>

<template>

    <section class="dashboard">

        <!-- LOADING -->
        <template v-if="!this.loaded">
            <div  v-for="i in this.icontrol.namespaces.length" :key="i">
                <ic-loading>
                    <rect x="0" y="10" rx="3" ry="3" width="20%" height="50" />
                    <rect x="0" y="80" rx="3" ry="3" width="100%" height="14" />
                    <rect x="0" y="110" rx="3" ry="3" width="100%" height="6" />
                    <rect x="0" y="120" rx="3" ry="3" width="50%" height="6" />
                </ic-loading>
            </div>
        </template>

        <template v-if="this.loaded">
            <div class="card" v-for="namespace in this.sortedNamespace" :key="namespace.name">
                <router-link class="absolute inset-0" :to="{ name: namespace.name }"></router-link>
                <h2>{{ namespace.name.match(/\b(\w)/g).join('') }}</h2>
                <h3>{{ namespace.name }}</h3>
                <p>{{ namespace.desc || 'Lorem ipsum...' }}</p>
            </div>
        </template>

    </section>

</template>

<style scoped>

    .dashboard          { @apply grid grid-cols-3 auto-rows-fr gap-4; }
    .dashboard .card    { @apply relative shadow-sm rounded-lg bg-body-accent p-5 space-x-3; }
    .dashboard .card h2 { @apply text-4xl font-bold text-primary; }
    .dashboard .card h3 { @apply mt-3 text-lg font-medium text-default; }
    .dashboard .card p  { @apply text-sm text-info; }

</style>
