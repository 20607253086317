import HiddenField      from './fields/HiddenField'
import StringField      from './fields/StringField.vue'
import EmailField       from './fields/EmailField.vue'
import PasswordField    from './fields/PasswordField.vue'
import NumberField      from './fields/NumberField.vue'
import SwitchField      from './fields/SwitchField.vue'
import OneToOneField    from './fields/OneToOneField.vue'
import ManyToManyField  from './fields/ManyToManyField.vue'
import SelectField      from './fields/SelectField.vue'
import FileField        from './fields/FileField.vue'
import TextField        from './fields/TextField.vue'
import ColorField        from './fields/ColorField.vue'

import Button           from './Button.vue'

import Form             from './Form.vue'

export default {
    install: (app) => {
        app.component('ic-color-field',         ColorField)
        app.component('ic-hidden-field',        HiddenField)
        app.component('ic-string-field',        StringField)
        app.component('ic-email-field',         EmailField)
        app.component('ic-password-field',      PasswordField)
        app.component('ic-number-field',        NumberField)
        app.component('ic-switch-field',        SwitchField)
        app.component('ic-one-to-one-field',    OneToOneField)
        app.component('ic-select-field',        SelectField)
        app.component('ic-text-field',          TextField)
        
        
        app.component('ic-many-to-many-field',  ManyToManyField)
        app.component('ic-file-field',          FileField)

        app.component('ic-button',              Button)

        app.component('ic-form',                Form)

    }
}
