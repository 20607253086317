
import Base                     from './templates/Base.vue'
import Auth                     from './templates/Auth.vue'
import NotFound                 from './templates/404.vue'
import IncompatibleBrowser      from './templates/Incompatible.vue'

import DashboardView            from './DashboardView.vue'
import DashboardNamespaceView   from './DashboardNamespaceView.vue'
import AdminView                from './AdminView.vue'

export default {

    Templates: {
        Base: Base,
        Auth: Auth,
        NotFound: NotFound,
        IncompatibleBrowser: IncompatibleBrowser,
    },

    DashboardView:          DashboardView,
    DashboardNamespaceView: DashboardNamespaceView,

    AdminView:              AdminView,

}
