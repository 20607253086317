<template>

    <ic-modal :show="this.show" :closable="false" :showHeader="false" :opaqueBackground="true" @click.stop="" @keyup.prevent="">
        <p class="text-center text-danger">
            Window resolution is insufficient.
        </p>
        <p class="text-center text-danger">
            <small class="text-sm">
                Please use your desktop to access iControl administration pannel.
            </small>
        </p>
    </ic-modal>

</template>

<script>
    const size = parseInt(window.getComputedStyle(document.documentElement).fontSize);

    export default {
        data() { return {
            show: false,
        }},
        methods: {
            handleResize() {
                let width = window.innerWidth / size;
                let height = window.innerHeight / size;
                if (width < 70 || height < 35) {
                    this.show = true
                } else {
                    this.show = false
                }
            }
        },
        mounted() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        unmounted() {
            window.removeEventListener('resize', this.handleResize);
        }
    }
</script>

<style scoped>
   .light {
        & aside{
                        background: hsla(0, 0%, 100%, 1);
        }
    }
    
    .dark {
        & aside{
                        background: hsla(0, 0%, 25%, 1);
        }
    }
</style>
