<script>
    export default {
  methods: {
    currentDate() {
      const current = new Date();
      return current.getFullYear();
    }
  }
};

</script>

<template>

    <div class="watermark">
        <ic-icons name="Digitalblend" />
    </div>
    <div class="copyright">© Digitalblend {{currentDate()}}</div>

</template>

<style scoped>

    .watermark {
        @apply absolute z-50 left-5 bottom-5 h-5 w-5;
    }
    .copyright {
        @apply absolute z-50 right-5 bottom-5 font-light text-sm;
    }

    .light {
        & .watermark {
            color: rgba(177, 188, 0, 1);
        }
        & .copyright {
            color: rgba(123, 123, 123, 1);
        }
    }

    .dark {
        & .watermark {
            color: rgba(220, 219, 15, 1);
        }
        & .copyright {
            color: rgba(198, 198, 198, 1);
        }
    }

</style>
