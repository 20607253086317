<template>

    <transition
        enter-active-class="ease-out duration-300 transition-opacity"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-300 transition-opacity"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <aside :class="this.opaqueBackground ? 'modal modalOpaque' : 'modal'" v-if="this.show" @click.self="this.closable ? $emit('update:show', false) : ''">
            <div class="ic-liquid">

                <span class="close" v-if="this.closable" @click="this.closable ? $emit('update:show', false) : ''">
                    <ic-icons name="X" />
                </span>

                <div class="wrapper">

                    <div class="header" v-if="this.showHeader">
                        <slot name="header">
                            <div class="buttons">
                                <slot name="header-buttons">
                                    <ic-button size="form" variant="cancel" @click="$emit('update:show', false)">Cancel</ic-button>
                                </slot>
                            </div>
                            <div class="title">
                                <slot name="header-texts">
                                    <h1 class="h1"><slot name="header-title">Title</slot></h1>
                                    <h2 class="h2"><slot name="header-subtitle">Neque porro quisquam est.</slot></h2>
                                </slot>
                            </div>
                        </slot>
                    </div>

                    <div class="content">
                        <slot></slot>
                    </div>

                </div>

            </div>
        </aside>
    </transition>

</template>

<script>
    export default {
        props: {
            show: { type: Boolean, required: true },
            closable: { type: Boolean, default: true },
            showHeader: { type: Boolean, default: true },
            opaqueBackground: { type: Boolean, default: false },
        },
        
        emits: [
            'update:show'
        ]
    }
</script>

<style scoped>

	.modal                                              { @apply fixed z-50 inset-0 flex justify-center items-center }
    
    aside {
        margin: auto auto;
        position: relative;
        padding: 20px 20px;
        width: 100%;
        height: 100%;
    }
    
    .light {
        & aside{
                        background: hsla(0, 0%, 90%, .95);
        }
    }
    
    .dark {
        & aside{
                        background: hsla(0, 0%, 25%, .95);
        }
    }

    .modal .close                                       { @apply absolute top-4 right-10 cursor-pointer text-default hover:text-primary; }

    .modal .wrapper                                     { @apply flex flex-col justify-center pt-14 pb-14 h-screen max-h-screen w-auto max-w-4xl mx-auto; }

    .modal .wrapper .header                             { @apply flex items-stretch justify-between; }
	.modal .wrapper .header .buttons                    { @apply inline-flex pt-4 pr-5 space-x-2 align-bottom; }
	.modal .wrapper .header .title                      { @apply flex-grow pl-5 mb-1 text-right border-b border-b-default align-bottom; }
	.modal .wrapper .header .title:deep() .h1           { @apply text-lg leading-6 font-medium text-default; }
	.modal .wrapper .header .title:deep() .h2           { @apply mt-1 font-light text-sm text-default; }

    .modal .wrapper .content                            { @apply relative h-screen p-5 overflow-auto text-left text-default; }

</style>
