import axios from 'axios'

export default new class API {

    set url(url) {
        this.http.defaults.baseURL = url
    }

    get url() {
        return this.http.defaults.baseURL
    }

    // MARK: - Inits
    constructor() {

        var self = this // scope

        this.http = axios.create({ withCredentials: true, })

        // middleware : auto login refresh at token expired then retry request
        this.http.interceptors.response.use(
            response => response,
            error => {
                const config = error.config
                if (error.response &&
                    error.response.status &&
                    error.response.status === 401 &&
                    !config._retry)
                {
                    return this.Auth.refresh()
                        .then(token => {
                            config.headers.Authorization = 'Bearer ' + token
                            config._retry = true
                            return this.http(config)
                        })
                        .catch(error => {
                            if (error.response && error.response.status && error.response.status === 401) {
                                return this.Auth.logout()
                            } else {
                                throw error
                            }
                        })
                } else {
                    throw error
                }
            }
        )

        // MARK: - Auth Routes
        this.Auth = {
            login: function(credentials) {
                return self.http.post("login/", credentials)
                    .then(response => response.data.token)
                    .then(token => { self.http.defaults.headers.common['Authorization'] = 'Bearer ' + token ; return token })
            },
            refresh: function() {
                return self.http.post('refresh/')
                    .then(response => response.data.token)
                    .then(token => { self.http.defaults.headers.common['Authorization'] = 'Bearer ' + token ; return token })
                    .catch(error => Promise.reject(error.response ? error.response.data.reason : error))
            },
            logout: function() {
                return self.http.post('logout/')
            },
            register: function(user) {
                return self.http.post("register/", user)
            },
            invite: function(email) {
                return self.http.post("invite/", email)
            },
            activate: function(form) {
                return self.http.post("activate/", form)
            },
            forgottenPassword: function(form) {
                return self.http.post("forgotten-password/", form)
            },
            recoverPassword: function(form) {
                return self.http.post("recover-password/", form)
            },
            cancelDelete: function(form) {
                return self.http.post("cancel-delete/", form)
            }
        }

        // MARK: - iControl Routes
        this.Infos = function() {
            return self.http.get("infos/")
                .then(response => response.data)
        },


        // MARK: - Namespace Routes
        this.Namespace = {

            Infos: function(url) {
                return self.http.get(url + "infos/")
                    .then(response => response.data)
            },

            Settings: {

                Schema: function(url) {
                    return self.http.get(url + 'settings/schema/')
                        .then(response => response.data)
                },

                get: function(url) {
                    return self.http.get(url + 'settings/')
                        .then(response => response.data)
                },

                set: function(url, model) {
                    return self.http.patch(url + 'settings/', model)
                },

            }

        }

        this.Module = {

            Infos: function(url) {
                return self.http.get(url + "infos/")
                    .then(response => response.data)
            }

        }

        // MARK: - Model Routes
        this.ModelModule = {

            Infos: function(url, params) {
                return self.http.get(url + "infos/", { params: params })
                    .then(response => response.data)
            },

            items: function(url, page, perPage, params) {
                return self.http.get(url, { params: Object.assign({}, params, { page: page, per: perPage, }) })
                    .then(response => response.data)
            },

            item: function(url, id, params) {
                return self.http.get(url + id + "/", { params: params })
                    .then(response => response.data)
            },

            create: async function(url, model, onProgress, params) {
                var data = {}
                // sequencial processing reduce
                // https://advancedweb.hu/how-to-use-async-functions-with-array-foreach-in-javascript/
                await Object.entries(model).reduce(async (memo, [key, value]) => {
                    await memo;
                    switch(true) {
                        case value instanceof File: {
                            // **withCredentials: true,** next to headers:
                            // so we can reuse self.http.put (swift object storage can't have withCredentials header )
                            let res = await axios.put(
                                value.options.url,
                                value,
                                {
                                    headers: {
                                        'Content-Type': value.typeo,
                                        'Content-Disposition': 'attachment; filename=' + value.name
                                    },
                                    onUploadProgress: progress => onProgress(progress.loaded / progress.total * 100),
                                }
                            )
                            if (res.status >= 200 && res.status < 300) {
                                data[key] = value.options.fileName
                            }
                            break;
                        }
                        default:
                            data[key] = value
                            break;
                    }
                }, undefined)
                return self.http.post(
                        url,
                        data,
                        {
                            onUploadProgress: progress => onProgress(progress.loaded / progress.total * 100 / 2),
                            onDownloadProgress: progress => onProgress(50 + (progress.loaded / progress.total * 100 / 2)),
                            params: params
                        }
                    )
                    .then(response => response.data)
            },

            createSchema: function(url, params) {
                return self.http.get(url + 'schema/', { params: params })
                    .then(response => response.data)
            },

            update: async function(url, model, onProgress, params) {
                var data = {}
                // sequencial processing reduce
                // https://advancedweb.hu/how-to-use-async-functions-with-array-foreach-in-javascript/
                await Object.entries(model).reduce(async (memo, [key, value]) => {
                    await memo;
                    switch(true) {
                        case value instanceof File: {
                            let res = await axios.put(value.options.url, value, { headers: {
                                        'Content-Type': value.type,
                                        'Content-Disposition': 'attachment; filename=' + value.name
                                    }, })
                            if (res.status >= 200 && res.status < 300) {
                                data[key] = value.options.fileName
                            }
                            break;
                        }
                        default:
                            data[key] = value
                            break;
                    }
                }, undefined)
                return self.http.patch(
                        url + model.id + "/",
                        data,
                        {
                            onUploadProgress: progress => onProgress(progress.loaded / progress.total * 100 / 2),
                            onDownloadProgress: progress => onProgress(50 + (progress.loaded / progress.total * 100 / 2)),
                            params: params
                        }
                    )
                    .then(response => response.data)
            },

            updateSchema: function(url, id, params) {
                return self.http.get(url + id + '/schema/', { params: params })
                    .then(response => response.data)
            },

            delete: function(url, id, onProgress, params) {
                return self.http.delete(
                        url + id + "/",
                        {
                            onUploadProgress: progress => onProgress(progress.loaded / progress.total * 100 / 2),
                            onDownloadProgress: progress => onProgress(50 + (progress.loaded / progress.total * 100 / 2)),
                            params: params
                        }
                    )
            },

        }

    }

}
