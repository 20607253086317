<template>

    <form class="form" v-on:submit.prevent="">
        <slot name="schema" :form="this" :schema="this.schema">
            <div :class="{ field: !field.hidden, hiddenfield: field.hidden }" v-for="field in schema" :key="field">
                <div class="label">
                    <label>{{ field.label }}<span v-if="field.required && !field.readonly"> * </span></label>
                    <br>
                    <small v-if="!field.readonly">{{ field.help }}</small>
                </div>
                <div class="input">
                    <component :is="this.fields[field.type]" v-model="value[field.key]" :error="errors[field.key]" :field="field"></component>
                </div>
            </div>
        </slot>
        <slot name="errors">
            <div class="errors" v-if="typeof(errors) === 'string'">
                <small>{{ errors }}</small>
            </div>
        </slot>
        <slot name="infos">
            <div class="infos" v-if="displayInfos">
                <p><span>*</span> Required fields.</p>
            </div>
        </slot>
    </form>
</template>

<script>
    export default {
        props: {
            modelValue: { type: Object, required: true },
            schema: { type: Object, required: true },
            errors: { required: false },
            displayInfos: { type: Boolean, default: true, }
        },
        emits: [
            'update:modelValue',
        ],
        computed: {
            value: {
                get() { return this.modelValue },
                set(value) { this.$emit('update:modelValue', value) }
            }
        },
        data() { return {
            fields: {
                'HiddenField': 'ic-hidden-field',
                'StringField': 'ic-string-field',
                'EmailField': 'ic-email-field',
                'PasswordField': 'ic-password-field',
                'NumberField': 'ic-number-field',
                'BooleanField': 'ic-switch-field',
                'OneToOneField': 'ic-one-to-one-field',
                'ManyToManyField': 'ic-many-to-many-field',
                'FileField': 'ic-file-field',
                'SelectField': 'ic-select-field',
                'TextField': 'ic-text-field',
                'ColorField': 'ic-color-field',
            }
        }},
    }
</script>


<style scoped>

    .form .field                        { @apply grid grid-cols-5 items-start py-4; }
    .form .hiddenfield                  { @apply border-none py-0; }
    .form .field .label                 { @apply col-span-2; }
    .form .field .label label           { @apply text-sm font-medium text-default; }
    .form .field .label label span      { @apply text-danger; }
    .form .field .label small           { @apply pt-1 font-light text-xs text-default; }

    .form .field .input                 { @apply col-span-3; }


    .form .field:deep() input           { @apply appearance-none focus:outline-none; }
    .form .field:deep() input           { @apply block w-full px-3 py-1 border border-default rounded-md bg-transparent text-default placeholder-info; }
    .form .field:deep() input:read-only { @apply border-none px-0; }
    .form .field:deep() input.error     { @apply border-danger; }
    .form .field:deep() small           { @apply font-light text-xs; }
    .form .field:deep() small.error     { @apply text-danger; }

    .form .errors                       { @apply flex items-center justify-center p-3; }
    .form .errors small                 { @apply font-light text-xs text-danger; }

    .form .infos p                      { @apply pt-5 font-thin text-sm text-default; }
    .form .infos p span                 { @apply text-danger; }

</style>
