<template>

    <div class="ic-container">

        <!-- HEADER -->
        <header>
            <div class="ic-liquid">
                <nav class="nav">
                    <!-- BREADCRUMB -->
                    <div class="breadcrumb">
                        <ol>
                            <li class="icontrol">
                                <router-link :to="{ name: 'Dashboard' }" :class="{ 'current': 0 == crumbs.length }">
									<div ref="iconAnimated"></div>
									<span>{{ this.iControl.name }}</span>
                                </router-link>
                            </li>
                            <li :class="{ 'current': index+1 == crumbs.length }"
                                v-for="(item, index) in crumbs" :key="item.label">
								<router-link :to="item.to">
									{{ item.label }}
								</router-link>
                            </li>
                        </ol>
                    </div>
                    <!-- USER INFOS -->
                    <div class="userinfo">
						<span>Welcome <span>{{ $store.getters['auth/user']?.email.split('@')[0] }}</span></span>
                        <span class="w-3"></span>
                        <button @click="this.settings.show = !this.settings.show">
                            <ic-icons name="Cog" />
                        </button>
                        <ic-modal v-model:show="this.settings.show" :showHeader="false">
                            <template v-slot>
                                <div class="settings">
                                    <h1 class="h1">iControl Settings</h1>
                                    <button class="logout" @click="this.logout()">
                                        <span>Logout</span><ic-icons name="Logout" />
                                    </button>
                                    <div class="thread">
                                        <div class="lngs">
                                            <span class="current">EN</span>
                                            <!-- <span>FR</span> -->
                                        </div>
                                        <div class="modes">
                                            <span>Light Mode</span>
                                            <button class="switch" @click="$store.dispatch('theme/toggle')" :class="$store.getters['theme/current'] == 'dark' ? 'goright' : 'goleft'"></button>
                                            <span>Dark Mode</span>
                                        </div>
                                    </div>
                                    <br>
                                    <button class="pagelink">
                                        <ic-icons name="User" /><span>My account - <span>{{ $store.getters['auth/user']?.email }}</span></span>
                                    </button>
                                    <button class="pagelink">
                                        <ic-icons name="DocumentText" /><span>Terms and conditions of use</span>
                                    </button>
                                </div>
                            </template>
                        </ic-modal>
                        <span class="w-3" v-if="$store.getters['auth/anyAdmin']" />
                        <button v-if="$store.getters['auth/anyAdmin']" @click="$router.push('/admin')">
                            <ic-icons name="Adjustments" />
                        </button>
                    </div>
                </nav>
				<!-- NOTIFICATIONS  -->
				<aside class="notifications">
					<ic-notifications/>
				</aside>
            </div>
        </header>

        <!-- MAIN -->
        <main>
            <div class="ic-liquid">
                <router-view v-slot="{ Component, route }">
                    <transition name="fade" mode="out-in">
                        <component :is="Component" :key="route.path"></component>
                    </transition>
                </router-view>
            </div>
        </main>

        <ic-screen-size-guard />

        <!-- FOOTER -->
        <footer>
            <ic-watermark />
        </footer>

    </div>

</template>

<script>
    import lottie from 'lottie-web'

    export default {
        props: {
            iControl: Object,
        },
        data() { return {
            settings: {
                show: false,
            },
        }},
        computed: {
            crumbs() {
                var routes = []
                let path = this.$route.path.replace(/\/$/, "").split("/")
                while (path.length) {
                    var route = this.$router.resolve(path.join('/'))
                    routes.push({ label: route.meta.name || route.name, to: route })
                    path.pop()
                }
                routes.pop()
                return routes.reverse()
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('auth/logout')
                    .then(() => this.$router.push('/login'))
            }
        },
        mounted() {
            lottie.loadAnimation({
                container: this.$refs.iconAnimated,
                loop: true,
                autoplay: true,
                animationData: this.iControl.iconAnimated
            })
        },
    }
</script>

<style scoped>

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s ease;
    }


    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    header                                              { @apply fixed z-50 h-auto w-full bg-header shadow; }


    /* NAV */
    header .nav                                         { @apply flex items-center my-3; }

    header .nav .breadcrumb                             { @apply flex-grow; }
	header .nav .breadcrumb ol                          { @apply relative flex items-baseline space-x-4; }
    header .nav .breadcrumb li                          { @apply flex align-baseline text-sm text-default; }
    header .nav .breadcrumb li.current                  { @apply text-primary cursor-default; }
    header .nav .breadcrumb li.icontrol                 { @apply text-xl; }
    header .nav .breadcrumb li.icontrol > a             { @apply flex items-baseline; }
	header .nav .breadcrumb li.icontrol > a > div       { top: 50%; transform: translateY(-50%); @apply absolute left-0 h-12 w-12; }
	header .nav .breadcrumb li.icontrol > a > span      { @apply relative pl-16 text-default font-medium; }
	header .nav .breadcrumb li:not(.icontrol):before    { content:'/'; @apply flex font-light text-default pr-4; }

	header .nav .userinfo                               { @apply flex items-center pl-4 text-right text-default; }
    header .nav .userinfo > span                        { @apply relative block whitespace-nowrap text-default; }
    header .nav .userinfo > span > span                 { @apply text-primary; }


    /* NOTIFS */
    header .notifications                               { @apply fixed z-40 top-0; left:50%; transform:translateX(-50%); }


    /* SETTINGS */
	header .settings .h1                                { @apply text-3xl text-default leading-6 font-semibold mb-12; }

    header .settings .logout                            { @apply absolute right-3 top-6; }
    header .settings .logout span                       { @apply relative inline-block align-middle pr-1 uppercase text-default cursor-pointer; }
    header .settings .logout svg                        { @apply inline-block align-middle text-default cursor-pointer; }
    header .settings .logout:hover span                 { @apply text-danger; }
    header .settings .logout:hover svg                  { @apply text-danger; }

	header .settings .thread                            { @apply flex items-center my-8 p-1 border border-gray-300 rounded-lg ; }
	header .settings .thread > div                      { @apply flex flex-grow justify-center items-center text-center p-6; }

	header .settings .lngs                              { @apply block border-r border-gray-300; }
	header .settings .lngs > span                       { @apply text-default uppercase px-2; }
	header .settings .lngs > span.current               { @apply text-primary; }
	header .settings .lngs > span:not(:first-of-type)   { @apply border-l border-gray-300; }

    header .settings .modes > span                      { @apply uppercase text-default px-8; }
    header .settings .modes > .switch                   { @apply relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer; }
    header .settings .modes > .switch:before            { content:''; @apply absolute h-4 w-10 mx-auto rounded-full ease-in-out duration-200 bg-body; }
    header .settings .modes > .switch:after             { content:''; @apply absolute left-0 h-5 w-5 inline-block rounded-full shadow transform ease-in-out duration-200 bg-body; }
    header .settings .modes > .switch.goleft:before     { @apply bg-body-accent; }
    header .settings .modes > .switch.goright:before    { @apply bg-body-accent; }
    header .settings .modes > .switch.goleft:after      { @apply translate-x-0 bg-primary; }
    header .settings .modes > .switch.goright:after     { @apply translate-x-5 bg-info; }

	header .settings .pagelink                          { @apply relative block h-7 pr-5 my-3 text-default hover:text-primary outline-none cursor-pointer; }
	header .settings .pagelink svg                      { @apply relative inline-block h-full align-middle; }
	header .settings .pagelink > span                   { @apply relative inline-block align-middle pl-4 uppercase; }
	header .settings .pagelink > span > span            { @apply lowercase; }


    /* MAIN */
    main                                                { @apply h-full w-full bg-body pt-16 pb-12; }

</style>
