<template>
    <textarea v-on:keyup.enter.prevent="" v-on:submit.prevent="" cols="50" class="resize-y rounded-md" :class="{ error: this.error !== undefined }"
        v-model="this.value"
        type="text"
        :placeholder="this.field.label"
    ></textarea>
    <small :class="{ error: this.error !== undefined }">{{ error }}</small>

</template>

<script>
    import Field from './Field.vue'
    export default {
        mixins: [Field]
    }
</script>

<style scoped>
        .light {
        & textarea{background-color:var(--color-body-accent);color: var(--color-default);}
    }
    
    .dark {
        & textarea{background-color:var(--color-body-accent);color: var(--color-default);}
    }
</style>
