<template>

    <div class="relative" v-if="this.field.options.options.length" >
        <button class="button py-2 px-4 bg-body shadow-xl rounded-lg" v-click-outside="this.clickOutside"  @click.stop="this.show = !this.show">
            <slot name="button">{{this.value}} ▼</slot>
        </button>
        <div class="content bg-body shadow-xl rounded-lg" v-show="this.show" >
            <div v-for="option in this.sortedFields" :key="option.name"
            @click.prevent="value == option.value ? value = undefined : value = option.value"
                            :for="option.name" >
                <label class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 cursor-pointer">
                    <input :id="option.name" type="button" :value="option.value">
                </label>
            </div>
        </div>
    </div>
    <div v-else>
        <label class="font-thin text-sm text-info">No available options</label>
    </div>
    <small :class="{ error: this.error !== undefined }">{{ error }}</small>
</template>
            
<script>
    import Field from './Field.vue'


    export default {
        data() {
            return {
                show: false,
                choice: this.field.value
                }
        },
        mixins: [Field],
        computed: {
            sortedFields() {
                return this.field.options.options.slice().sort((left, right) => {
                    let fa = left.name.toLowerCase(), fb = right.name.toLowerCase();
                    if (fa < fb) {
                        return -1
                    }
                    if (fa > fb) {
                        return 1
                    }
                    return 0
                })
            }
        },
        methods: {
            clickOutside(event) {
                event
                if (this.show) {
                    this.show = false
                }
            }
        },
        
        props: {
            modelValue: { default: "None" }  // default value
        }
    }
            
</script>

<style scoped>

    .content { @apply absolute z-50 p-3; text-align: center; border-width: 1px; border-color: var(--color-body-accent) }
    .button:hover { background-color: var(--color-body-accent) }
    .content:deep() * { @apply rounded my-1; }
    .content:deep() *:hover { background-color: var(--color-body-accent) }

</style>
