<template>

    <div>
        <div class="wrapper">
            <div class="header">
                <h2>{{ this.title }}</h2>
            </div>
            <div v-if="!!this.$slots.default" class="body">
                <slot></slot>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props: {
            title: { type: String, required: true, }
        }
    }
</script>

<style scoped>

    div             { @apply flex max-h-full w-full max-w-full my-1 mr-3; }
    div .wrapper    { @apply block rounded-lg shadow-md bg-body-accent2 overflow-hidden border-l border-r border-body-accent; };

    .header         { @apply py-6 pl-6 m-0 bg-body-accent text-left text-xs font-medium uppercase tracking-wider text-default; }
    .body           { @apply p-3 block bg-body-accent2 overflow-y-scroll rounded-b-lg ; }

</style>
