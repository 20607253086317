<template>
    <div class="h-full max-h-full">
        <ic-crud name="Records" url="mms/unitybundles/" @items="this.records = $event"
            :updateAction="true"
            :deleteAction="true"
            :fields="['id', 'name', 'file']"
            :fieldsFormatter="{'file': 'file'}"
        >
        </ic-crud>
    </div>
</template>

<script>
    export default {
        props: {
            module: { type: Object, required: true },
        },
    }
</script>

