<template>

    <div v-if="this.active">
        <slot></slot>
    </div>

</template>

<script>
    export default {
        props: {
            title: { type: String, },
            titleIcon: { type: String, },
            badge: { type: String, },
        },
        provide() { return {
            sub: this,
        }},
        inject: ['menu', ],
        data() { return {
            active: false,
            items: []
        }},
        mounted() {
            let index = this.menu.items.findIndex(item => { return item.title == this.title })
            if (index === -1) {
                this.menu.items.push(this)
            }
        },
        unmounted() {
            let index = this.menu.items.findIndex(item => { return item.title == this.title })
            if (index !== -1) {
                this.menu.items.splice(index, 1)
            }
        }
    }
</script>

<style scoped>

    div { @apply block h-full max-h-full w-full max-w-full; }

</style>
