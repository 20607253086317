<template>

    <ic-menu>

        <ic-menu-item :title="this.icontrol.name" titleIcon="Cube"
            v-if="$store.getters['auth/admin']"
        >
            <AdminSuperView :icontrol="this.icontrol" />

        </ic-menu-item>


        <ic-menu-item :title="namespace.name" titleIcon="CubeTransparent"
            v-for="namespace in this.namespaces" :key="namespace.name"
        >
            <AdminNamespaceView :namespace="namespace" />
        </ic-menu-item>

    </ic-menu>

</template>

<script>
    import AdminSuperView       from './AdminSuperView.vue'
    import AdminNamespaceView   from './AdminNamespaceView.vue'

    export default {
        components: {
            AdminSuperView,
            AdminNamespaceView
        },
        props: {
            icontrol: { type: Object, required: true, },
        },
        computed: {
            namespaces() {
                return this.icontrol.namespaces.filter(namespace => this.$store.getters['auth/adminOf'](namespace.name))
            }
        },
    }
</script>

<style scoped>

    .infos-card dl                  { @apply grid grid-cols-3; }
    .infos-card dl dt               { @apply col-span-1 py-2 font-medium text-default flex items-center; }
    .infos-card dl dt.sub           { @apply pt-0 pl-3; }
    .infos-card dl dd               { @apply col-span-2 font-light text-info flex items-center; }

</style>
