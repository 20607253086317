<template>

    <ic-menu-subitem title="Users" titleIcon="Users">
        <ic-crud name="Users" :url="this.namespace.path + '/users/'" @items="this.users = $event"
            :fields="['email', 'active', 'admin', 'policy', 'tag',  'createdAt' ]"
            :fieldsFormatter="{ 'active': 'bool', 'admin': 'bool', 'policy': this.usersFormatPolicy, 'tag': this.usersFormatTag, 'createdAt': 'date' }"
            lookupField="email"
        />
    </ic-menu-subitem>


    <ic-menu-subitem title="Policies" titleIcon="LockOpen">
        <ic-crud name="Policy" :url="this.namespace.path + '/policies/'"
            :fields="['name', 'createdAt', ]"
            :fieldsFormatter="{ 'createdAt': 'date' }"
            lookupField="name"
        />
    </ic-menu-subitem>
    <ic-menu-subitem title="Apps" titleIcon="DeviceMobile">
        <ic-crud name="App" :url="this.namespace.path + '/apps/'"
            :fields="['appIcon', 'name', 'createdAt', ]"
            :fieldsFormatter="{ 'appIcon': 'image', 'createdAt': 'date' }"
            lookupField="name"
            :showIndex="true"
        />
    </ic-menu-subitem>
    
        <ic-menu-subitem title="Themes" titleIcon="ColorSwatch">
        <ic-crud name="App" :url="this.namespace.path + '/themes/'"
            :fields="['name', 'primary','secondary']"
            :fieldsFormatter="{'createdAt': 'date','primary':'color', 'secondary':'color' }"
            lookupField="name"
            :showIndex="true"
        />
    </ic-menu-subitem>

    <ic-menu-subitem title="Tags" titleIcon="Tag">
        <ic-crud name="Tag" :url="this.namespace.path + '/tags/'"
            :fields="['name', 'createdAt', ]"
            :fieldsFormatter="{ 'createdAt': 'date' }"
            lookupField="name"
        />
    </ic-menu-subitem>

    <ic-menu-subitem title="Logs" titleIcon="ClipboardList">
        <ic-crud name="Log" :url="this.namespace.path + '/logs/'"
            :createAction="false"
            :updateAction="false"
            :deleteAction="false"
            :fields="['date', 'text', ]"
            :fieldsFormatter="{ 'date': 'date' }"
        />
    </ic-menu-subitem>

    <ic-menu-subitem title="Settings" titleIcon="Cog">
        <ic-card title="Settings" v-if="this.infos">
            <ic-form v-model="this.settingsModel"
                :schema="this.settingsSchema"
                :errors="this.settingsErrors"
                :displayInfos="false"
            >
            </ic-form>
            <div class="text-right">
                <ic-button size="sm" variant="primary" @click="this.setSettingsSubmit()">Save</ic-button>
            </div>
        </ic-card>
    </ic-menu-subitem>

    <ic-menu-subitem title="Infos" titleIcon="InformationCircle">
        <ic-card class="infos-card" title="Namespace Informations" v-if="this.infos">
            <dl>
                <dt>{{ this.namespace.name }} Version</dt>
                <dd>{{ this.infos[this.namespace.name + "Version"] }}</dd>
                <template v-for="module in this.namespace.modules" :key="module.name">
                    <dt class="sub">{{ module.name }} Version</dt>
                    <dd>{{ this.infos[module.name + "Version"] }}</dd>
                </template>
            </dl>
        </ic-card>
    </ic-menu-subitem>

</template>

<script>
    export default {
        props: {
            namespace: { type: Object, required: true, },
        },
        data() { return {
            users: [],
            usersPolicies: [],
            usersTags: [],
            settingsSchema: [],
            settingsModel: {},
            settingsErrors: {},
            infos: undefined,
        }},
        methods: {
            usersFormatPolicy(policyID) {
                return this.usersPolicies.find(policy => policy.id == policyID)?.name
            },
            usersFormatTag(tagID) {
                return this.usersTags.find(tag => tag.id == tagID)?.name
            },
            setSettingsSubmit() {
                this.$api.Namespace.Settings.set(this.namespace.path + "/", this.settingsModel)
                    .then(() => this.$notify(`Settings saved.`, 'success'))
                    .catch(error => this.settingsErrors = error.response.data.errors || error.response.data.reason || error.message || error )
            }
        },
        mounted() {
            this.$api.Namespace.Infos(this.namespace.path + "/").then(infos => { this.infos = infos })
            this.$api.Namespace.Settings.Schema(this.namespace.path + "/")
                .then(settingsSchema => this.settingsSchema = settingsSchema)
            this.$api.Namespace.Settings.get(this.namespace.path + "/")
                .then(settings => this.settingsModel = settings)
            this.$api.ModelModule.items(this.namespace.path + "/policies/", 1, 999).then(data => { this.usersPolicies = data.items })
            this.$api.ModelModule.items(this.namespace.path + "/tags/", 1, 999).then(data => { this.usersTags = data.items })
        }
    }
</script>

<style scoped>

    .infos-card dl                  { @apply grid grid-cols-3; }
    .infos-card dl dt               { @apply col-span-1 py-2 font-medium text-default flex items-center; }
    .infos-card dl dt.sub           { @apply pt-2 pl-5; }
    .infos-card dl dd               { @apply col-span-2 font-light text-info flex items-center; }

</style>
