import API from "../api/API.js"

export default {
    namespaced: true,
    state: {
        current: null,
    },
    getters: {
        isAuthenticated: state => !!state.current,
        token: state => state.current,
        user: state => state.current ? JSON.parse(atob(state.current.split('.')[1])) : null,
        admin: (state, getters) => {
            if (getters.user) {
                return getters.user.admin
            }
            return false
        },
        adminOf: (state, getters) => (namespace) => {
            if (getters.user) {
                return getters.user.admin || getters.user.admins.includes(namespace)
            }
            return false
        },
        anyAdmin: (state, getters) => {
            if (getters.user) {
                return getters.user.admin || (getters.user.admins.length != 0)
            }
            return false
        }
    },
    actions: {
        login({ commit }, credentials) {
            return API.Auth.login(credentials)
                .then(token => { commit("loginSuccess", token) })
                .catch(error => { commit("loginError"); throw error })
        },
        refresh({ commit }) {
            return API.Auth.refresh()
                .then(token => commit("loginSuccess", token))
        },
        logout({ commit }) {
            return API.Auth.logout()
                .then(() => commit("logout"))
        },
        register(_, user) {
            return API.Auth.register(user)
        },
        invite(_, email) {
            return API.Auth.invite(email)
        },
        activate(_, form) {
            return API.Auth.activate(form)
        },
        forgottenPassword(_, email) {
            return API.Auth.forgottenPassword(email)
        },
        recoverPassword(_, form) {
            return API.Auth.recoverPassword(form)
        },
        cancelDelete(_, form) {
            return API.Auth.cancelDelete(form)
        }
    },
    mutations: {
        loginSuccess(state, token) {
            state.current = token
        },
        loginError(state) {
            state.current = null
        },
        logout(state) {
            state.current = null
        },
    },

}
