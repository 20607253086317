<template>
    <template v-if="this.loaded">
        <ic-table
            :fields="fields"
            :fieldsFormatter="fieldsFormatter"
            :items="items"
            :showIndex="showIndex"
            @scrolled="this.items.length < this.total ? this.fetchItems() : ''"
            @selected="$emit('itemsSelected', $event)"
        >
            <template v-slot:th>
                <div class="relative flex items-center justify-end">
                    <button class="m-1 px-1 py-0.5"
                        v-if="this.showExports"
                        @click.stop="this.exportShow = !this.exportShow"
                    >
                        <ic-icons name="Download" size="sm" />
                    </button>
                    <div class="relative"
                        v-if="this.showExports"
                        v-show="this.exportShow"
                        v-click-outside="this.exportClickOutsideHandler"
                    >
                        <div class="absolute right-0 top-4 z-50 p-1 bg-body shadow-xl rounded-lg border border-body-accent">
                            <a href="#" class="block px-4 py-2 text-sm rounded-sm text-default hover:bg-body-accent" @click="exportJSON()">JSON</a>
                            <a href="#" class="block px-4 py-2 text-sm rounded-sm text-default hover:bg-body-accent" @click="exportCSV()">CSV</a>
                        </div>
                    </div>
                    <button class="m-1 px-1 py-0.5"
                        v-if="this.showRefresh"
                        @click="this.refreshItems()"
                    >
                        <ic-icons name="Refresh" size="sm" />
                    </button>
                    <button class="m-1 px-1 py-0.5"
                        v-if="this.createAction"
                        @click="this.createModal()"
                    >
                        <ic-icons name="Plus" size="sm" />
                    </button>
                    <slot name="th"></slot>
                </div>
            </template>
            <template v-if="!!this.$slots['thead']" v-slot:thead>
                <slot name="thead"></slot>
            </template>
            <template v-if="!!this.$slots['tbody']" v-slot:tbody="data">
                <slot name="tbody" :item="data.item"></slot>
            </template>
            <template v-if="!!this.$slots['field']" v-slot:field="data">
                <slot name="field" :item="data.item" :field="data.field"></slot>
            </template>
            <template v-slot:td="data">
                <div class="relative flex items-center justify-end">
                    <slot name="td" :item="data.item" :field="data.field">
                        <ic-button v-if="this.updateAction" @click="this.updateModel = { id: data.item.id }; this.updateModal();"
                            size='xs' variant='warning' outline>
                            <ic-icons name="PencilAlt" size="sm" />
                        </ic-button>
                        <ic-button v-if="this.deleteAction" @click="this.deleteModel = data.item; this.deleteModalShow = true;"
                            size='xs' variant='danger' outline>
                            <ic-icons name="Trash" size="sm" />
                        </ic-button>
                    </slot>
                </div>
            </template>
            <template v-slot:tfoot>
                Showing <span class="text-sm"> {{ this.items.length > 0 ? '1' : '0' }} </span> to <span class="text-sm">{{ this.items.length }}</span> of <span class="text-sm">{{ this.total }}</span> items
            </template>
        </ic-table>
        <ic-modal v-if="this.createAction" v-model:show="this.createModalShow">
            <template v-slot:header-title>{{ this.name }}</template>
            <template v-slot:header-subtitle>Add {{ this.name.toLowerCase() }}</template>
            <template v-slot:header-buttons>
                <ic-button size="sm" variant="primary" @click="this.createHandler()">Create</ic-button>
                <ic-button size="sm" variant="default" @click="this.createModalShow = false">Cancel</ic-button>
            </template>
            <ic-form v-on:submit.prevent="" v-show="!this.createModalBusy" v-model="this.createModel" :schema="this.createSchema" :errors="this.createErrors"></ic-form>
            <ic-progress v-show="this.createModalBusy" :progress="this.createModalProgress"></ic-progress>
        </ic-modal>
        <ic-modal v-if="this.updateAction"  v-model:show="this.updateModalShow">
            <template v-slot:header-title>{{ this.name }}</template>
            <template v-slot:header-subtitle>Edit {{ this.name.toLowerCase() }} [<span class="text-xs">{{ updateModel.id }}</span>]</template>
            <template v-slot:header-buttons>
                <ic-button size="sm" variant="warning" @click="this.updateHandler()">Update</ic-button>
                <ic-button size="sm" variant="default" @click="this.updateModalShow = false">Cancel</ic-button>
            </template>
            <ic-form v-on:submit.prevent="" v-show="!this.updateModalBusy" v-model="this.updateModel" :schema="this.updateSchema" :errors="this.updateErrors"></ic-form>
            <ic-progress v-show="this.updateModalBusy" :progress="this.updateModalProgress"></ic-progress>
        </ic-modal>
        <ic-modal v-if="this.deleteAction" v-model:show="this.deleteModalShow">
            <template v-slot:header-title>{{ this.name }}</template>
            <template v-slot:header-subtitle>Delete {{ this.name.toLowerCase() }} [<span class="text-xs">{{ deleteModel.id }}</span>]</template>
            <template v-slot:header-buttons>
                <ic-button size="sm" variant="danger" @click="this.deleteHandler()">Delete</ic-button>
                <ic-button size="sm" variant="default" @click="this.deleteModalShow = false">Cancel</ic-button>
            </template>
            <div v-show="!this.deleteModalBusy" class="flex flex-col items-center justify-center py-3">
                <p>Are you sure you want to delete this {{ this.name }} ?</p>
                <p class="font-bold"><span class="text-danger">{{ this.deleteModel[this.lookupField] }}</span> will be deleted</p>
                <div class="flex items-center justify-center" v-if="typeof(this.deleteErrors) === 'string'">
                    <small class="text-xs text-danger font-light">{{ this.deleteErrors }}</small>
                </div>
            </div>
            <ic-progress v-show="this.deleteModalBusy" :progress="this.deleteModalProgress"></ic-progress>
        </ic-modal>
    </template>
    <template v-else>
        <ic-loading class="w-full">
            <rect x="0" y="0" rx="3" width="90%" height="8%" />
            <rect x="91%" y="0" rx="3" width="8%" height="8%" />
            <template v-for="indexRow in 8" :key="indexRow">
                <template v-for="indexCol in this.fields.length" :key="indexCol">
                    <rect rx="3" ry="3" :y="(10 * (indexRow)) + '%'" :x="((90 / this.fields.length) * (indexCol - 1)) + 1 + '%'" :width="(90 / this.fields.length) - 2 + '%'" height="7"/>
                </template>
                <circle r="3" :cy="(10 * (indexRow)) + 2.5 + '%'" cx="94%" width="4%" height="7"/>
                <circle r="3" :cy="(10 * (indexRow)) + 2.5 + '%'" cx="96%" width="4%" height="7"/>
            </template>
            <rect x="1%" y="90%" rx="3" ry="3" width="98%" height="5%" />
        </ic-loading>
    </template>
</template>
<script>
    import { saveAs } from 'file-saver'
    import { parse }  from 'json2csv'
    export default {
        props: {
            name: { type: String, required: true, },
            url: { type: String, required: true },
            params: { type: Object, default: undefined },
            fields: { type: Array, default: () => [] },
            fieldsFormatter: { type: Object, default: () => { return {}}, },
            lookupField: { type: String, default: 'id' },
            showIndex: { type: Boolean, default: true, },
            showExports: { type: Boolean, default: true, },
            showRefresh: { type: Boolean, default: true, },
            loadAll: { type: Boolean, default: false, },
            createAction: { type: Boolean, default: true, },
            updateAction: { type: Boolean, default: true, },
            deleteAction: { type: Boolean, default: true, },
        },
        emits: [
            'items',
            "itemsSelected",
        ],
        data() { return {
            loaded: false,
            items: [],
            per: 10,
            page: 1,
            total: 0,
            createModalShow: false,
            createModalBusy: false,
            createModalProgress: 0,
            createSchema: [],
            createModel: {},
            createErrors: {},
            updateModalShow: false,
            updateModalBusy: false,
            updateModalProgress: 0,
            updateSchema: [],
            updateModel: {},
            updateErrors: {},
            deleteModalShow: false,
            deleteModalBusy: false,
            deleteModalProgress: 0,
            deleteModel: {},
            deleteErrors: {},
            exportShow: false,
        }},
        methods: {
            fetchItems() {
                this.$api.ModelModule.items(this.url, this.page++, this.per, this.params)
                    .then(page => {
                        this.total = page.metadata.total
                        this.items = [...this.items, ...page.items]
                        this.$emit('items', this.items)
                        if (this.loadAll && this.items.length < this.total) {
                            this.fetchItems()
                        } else {
                            if (((this.page - 1) < document.body.clientHeight / 500) && this.items.length < this.total) {
                                this.fetchItems()
                            }
                        }
                    })
            },
            async refreshItems() {
                this.loaded = false
                this.items = []
                this.total = 0
                this.per = 10
                this.page = 1
                await this.fetchItems()
                this.loaded = true
            },
            createModal() {
                this.createModalBusy = true
                this.$api.ModelModule.createSchema(this.url, this.params)
                    .then(schema => {
                        this.createSchema = schema
                        this.createModalShow = true
                    })
                    .then(() => { this.createModalBusy = false })
            },
            createHandler() {
                this.createErrors = {}
                this.createModalBusy = true
                this.createModalProgress = 0
                this.$api.ModelModule.create(this.url, this.createModel, progress => this.createModalProgress = progress, this.params)
                    .then(item => {
                        this.createModalShow = false
                        this.items.unshift(item)
                        this.total++
                        this.$emit('items', this.items)
                        this.$notify(`${item[this.lookupField]} was successfully created.`, 'success')
                        this.createModel = {}
                    })
                    .catch(error => {
                        this.createErrors = error
                    })
                    .then(() => { this.createModalBusy = false })
            },
            updateModal() {
                this.updateModalBusy = true
                this.$api.ModelModule.updateSchema(this.url, this.updateModel.id, this.params)
                    .then(schema => {
                        this.updateSchema = schema
                        this.updateModalShow = true
                    })
                    .then(() => { this.updateModalBusy = false })
            },
            updateHandler() {
                this.updateErrors = {}
                this.updateModalBusy = true
                this.updateModalProgress = 0
                this.$api.ModelModule.update(this.url, this.updateModel, progress => this.updateModalProgress = progress, this.params)
                    .then(item => {
                        this.updateModalShow = false
                        this.items[this.items.findIndex(item => item.id == this.updateModel.id)] = item
                        this.total++
                        this.$emit('items', this.items)
                        this.$notify(`${item[this.lookupField]} was successfully updated.`, 'success')
                    })
                    .catch(error => {
                        this.updateErrors = error
                    })
                    .then(() => { this.updateModalBusy = false })
            },
            deleteHandler() {
                this.deleteErrors = {}
                this.deleteModalBusy = true
                this.deleteModalProgress = 0
                this.$api.ModelModule.delete(this.url, this.deleteModel.id, progress => this.deleteModalProgress = progress, this.params)
                    .then(() => {
                        const item = this.items.find(item => item.id == this.deleteModel.id)
                        this.deleteModalShow = false
                        this.items.splice(this.items.findIndex(item => item.id == this.deleteModel.id), 1)
                        this.$emit('items', this.items)
                        this.total--
                        this.$notify(`${item[this.lookupField]} was deleted.`, 'success')
                    })
                    .catch(error => {
                        this.deleteErrors = error
                    })
                    .then(() => this.deleteModalBusy = false)
            },
            exportClickOutsideHandler() {
                if (this.exportShow) {
                    this.exportShow = false
                }
            },
            exportJSON() {
                var json = JSON.stringify(this.items, null, 4)
                var blob = new Blob([json], { type: 'text/plain;charset=utf-8' })
                saveAs(blob, `${this.name}.json`)
            },
            exportCSV() {
                var data = parse(this.items)
                var blob = new Blob([data], { type: 'text/plain;charset=utf-8' })
                saveAs(blob, `${this.name}.csv`)
            },
        },
        async mounted() {
            await this.refreshItems()
        },
    }
</script>
