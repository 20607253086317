import mitt             from 'mitt'
import Notifications    from './Notifications.vue'

export default {
    install: (app) => {

        const notifier = mitt()
        app.config.globalProperties.$notifier = notifier
        app.config.globalProperties.$notify = function(text, variant = 'info') {
            notifier.emit('notify', { text: text, variant: variant })
        }

        app.component('ic-notifications', Notifications)

    }
}
