<template>

    <input :class="{ error: this.error !== undefined }"
        v-model="this.value"
        type="password"
        autocomplete="password"
        :placeholder="this.field.label"
        :readonly="this.field.readonly"
    >
    <small :class="{ error: this.error !== undefined }">{{ error }}</small>

</template>

<script>
    import Field from './Field.vue'
    export default {
        mixins: [Field]
    }
</script>
