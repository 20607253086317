import Progress         from './Progress.vue'
import ParticlesBG      from './ParticlesBG.vue'
import Watermark        from './Watermark.vue'
import Loading          from './Loading.vue'
import ScreenSizeGuard  from './ScreenSizeGuard.vue'

export default {
    install: (app) => {

        app.component('ic-progress',            Progress)
        app.component('ic-particles-bg',        ParticlesBG)
        app.component('ic-watermark',           Watermark)
        app.component('ic-loading',             Loading)
        app.component('ic-screen-size-guard',   ScreenSizeGuard)

    }
}
