<template>

    <div v-if="this.field.options.options.length">
        <div class="grid grid-cols-2 gap-y-1">
            <div v-for="option in this.sortedFields" :key="option.name">
                <div class="flex items-center">
                    <label :for="option.name" class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer">
                        <input :id="option.name" type="checkbox" v-model="value" :value="option.value" class="hidden">
                        <span  :id="option.name + '-line'" class="absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"></span>
                        <span  :id="option.name + '-dot'" class="absolute h-5 w-5 left-0 inline-block rounded-full ring-0 border bg-white transform transition-transform ease-in-out duration-200"></span>
                    </label>
                    <div class="ml-3 text-sm">
                        <label :class="this.value.includes(option.value) ? 'font-medium text-default': 'font-light text-info'">{{ option.name }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="flex pt-4 justify-center items-center">
            <label class="font-thin text-sm text-info">No available options</label>
        </div>
    </div>
    <small :class="{ error: this.error !== undefined }">{{ error }}</small>

</template>

<script>
    import Field from './Field.vue'
    export default {
        mixins: [Field],
        computed: {
            sortedFields() {
                return this.field.options.options.slice().sort((left, right) => {
                    let fa = left.name.toLowerCase(), fb = right.name.toLowerCase();
                    if (fa < fb) {
                        return -1
                    }
                    if (fa > fb) {
                        return 1
                    }
                    return 0
                })
            }
        },
        props: {
            modelValue: { type: Array, default: () => [] }
        }
    }
</script>

<style scoped>

    input                           { @apply !border-none; }
    input ~ [id$="-line"]           { @apply bg-info; }
    input:checked ~ [id$="-line"]   { @apply bg-success; }
    input ~ [id$="-dot"]            { @apply translate-x-0; }
    input:checked ~ [id$="-dot"]    { @apply translate-x-5; }

</style>
