<template>

    <ic-menu-subitem title="Users" titleIcon="Users">
        <ic-crud name="User" url="users/"
            :fields="['email', 'active', 'admin', 'createdAt' ]"
            :fieldsFormatter="{ 'active': 'bool',  'admin': 'bool', 'createdAt': 'date' }"
            lookupField="email"
            :showIndex="true"
        />
    </ic-menu-subitem>

    <ic-menu-subitem title="Logs" titleIcon="ClipboardList">
        <ic-crud name="Log" url="logs/"
            :createAction="false"
            :updateAction="false"
            :deleteAction="false"
            :fields="['date', 'namespace', 'text', ]"
            :fieldsFormatter="{ 'date': 'date' }"
        />
    </ic-menu-subitem>

    <ic-menu-subitem title="Infos" titleIcon="InformationCircle">
        <ic-card class="infos-card" :title="this.icontrol.name + ' Informations'" v-if="this.infos">
            <dl>
                <dt>{{ this.icontrol.name }} Version</dt>
                <dd>{{ this.infos[this.icontrol.name + "Version"] }}</dd>
                <dt class="sub">iControl Version</dt>
                <dd>{{ this.infos.Version }}<ic-icons v-if="this.icontrol.version != this.infos.Version" class="text-danger" name="ShieldExclamation" /></dd>
                <dt class="sub">Front-End Version</dt>
                <dd>{{ this.icontrol.version }}<ic-icons v-if="this.icontrol.version != this.infos.Version" class="text-danger" name="ShieldExclamation" /></dd>
            </dl>
        </ic-card>
    </ic-menu-subitem>

</template>

<script>
    export default {
        props: {
            icontrol: { type: Object, required: true, },
        },
        data() { return {
            infos: undefined,
        }},
        mounted() {
            this.$api.Infos().then(infos => { this.infos = infos })
        }
    }
</script>

<style scoped>

    .infos-card dl                  { @apply grid grid-cols-3; }
    .infos-card dl dt               { @apply col-span-1 py-2 font-medium text-default flex items-center; }
    .infos-card dl dt.sub           { @apply pt-2 pl-5; }
    .infos-card dl dd               { @apply col-span-2 font-light text-info flex items-center; }

</style>
