<script>
    import lottie from 'lottie-web'

    export default {
        props: {
            iControl: Object
        },
        mounted() {
            lottie.loadAnimation({
                container: this.$refs.iconAnimated,
                loop: true,
                autoplay: true,
                animationData: this.iControl.iconAnimated
            })
        },
    }
</script>

<template>

    <!-- CONTAINER iControl -->
    <div class="ic-container">

		<ic-particles-bg />

        <!-- MAIN -->
		<main>
            <div class="ic-liquid">
				<div>
                    <div class="icon" ref="iconAnimated"></div>
					<h1 class="title">Error 404</h1>
					<h2 class="subtitle">Page Not Found</h2>
                    <router-link :to="{ name: 'Dashboard', }" class="back">
                        <ic-button variant="primary" size='sm'>Return to {{ this.iControl.name }}</ic-button>
                    </router-link>
				</div>
			</div>
		</main>

        <!-- FOOTER -->
        <footer>
            <ic-watermark />
        </footer>

	</div>

</template>

<style scoped>

	main { @apply pt-0 bg-transparent items-center; }
    main > div { @apply relative z-20 h-screen flex items-center text-center justify-center h-screen; }
	main .icon { @apply h-24 w-24 m-auto; }
	main .title { @apply font-bold text-4xl text-white mb-6 text-center ; }
	main .subtitle { @apply font-medium text-2xl text-white mb-4 text-center ; }

</style>
