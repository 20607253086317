<script>
    import
    iControl    from './iControl/iControl.vue'

    
    import UnityBundle             from './views/UnityBundle.vue'
    import AI                      from './views/AI.vue'

    iControl.name = 'Airbus'

    iControl.namespaces.push(
        {
            name: "MMS",
            desc: " Airbus Multi Media Studio.",
            path: "mms",
            modules: [
                {
                    name: "UnityBundle",
                    desc: "UnityBundle Management Interface.",
                    path: "unitybundles",
                    component: UnityBundle,
                    componentProps: {}
                }
                
            ],
        },
        {
            name: "AI",
            desc: " AI Multi Media Studio.",
            path: "ai",
            modules: [
                {
                    name: "Game Intro",
                    desc: "Game Intro Management Interface.",
                    path: "airbusgameintro",
                    component: AI,
                    componentProps: {}
                }
                
            ],
        },
    )

    iControl.start()

    export default {}
</script>
