<template>
    <ContentLoader class="w-full px-1 pt-1 pb-2"
            primaryColor="var(--color-body-accent)"
            secondaryColor="var(--color-body-accent2)"
        >
        <slot></slot>
    </ContentLoader>
</template>

<script>
    import { ContentLoader } from 'vue-content-loader'
    export default {
        components: {
            ContentLoader
        }
    }
</script>
