<template>
    <div></div>
    <!-- create search bar -->
    <div class="search absolute z-20">
        <div class="icon">
            <div class="loupe">
                <svg width="24" stroke="white"
                    height="24" xmlns="http://www.w3.org/2000/svg"
                    viewBox="-1 -1 40 40" fill-rule="evenodd"
                    clip-rule="evenodd">
                    <path d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5z" />
                </svg>
            </div>
            <input type="text" class="search-hover text-white-300 px-1 py-1"
                placeholder="search here..."
                onclick="console.log"
                v-model="search" />
        </div>
    </div>
    <div>
        <div class="wrapper" @scroll="scroll">
            <table>
                <thead>
                    <tr>
                        <th v-if="showIndex" class="index">#</th>
                        <th v-for="field in fields" :key="field" class="sort"
                            @click="sortByField = field ; sortOrder = sortOrder * -1">
                            <span>{{ field.replace('.', ' ').toUpperCase() }}</span>
                            <ic-icons
                                :name="this.sortByField == field ? this.sortOrder > 0 ? 'SortAscending' : 'SortDescending' : 'SortDescending'"
                                :stroke="this.sortByField == field ? '3' : '1'" />
                        </th>
                        <slot name="thead" />
                        <th v-if="!!this.$slots['th']" class="additional">
                            <slot name="th"></slot>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="this.sortedItems.length">
                    <tr v-for="(item, index) in sortedItems" :key="item" @click="select(item)">
                        <td v-if="showIndex" class="index">{{ index + 1 }}</td>
                        <td v-for="(field, index2) in fields" :key="field"
                            :class="index2 === 0 ? 'font-medium' : 'font-normal'">
                            <slot name="field" :item="item" :field="field">
                                <div v-html="format(item, field)"></div>
                                <!-- https://gist.github.com/jasonrhodes/2321581 -->
                            </slot>
                        </td>
                        <slot name="tbody" :item="item" />
                        <td v-if="!!this.$slots['td']">
                            <slot name="td" :item="item"></slot>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="999" class="text-center">no items</td>
                    </tr>
                </tbody>
                <tfoot v-if="!!this.$slots['tfoot']">
                    <tr>
                        <td colspan="100%">
                            <slot name="tfoot" />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>
<script>

export default {

    props: {
        fields: { type: Array, },
        fieldsFormatter: { type: Object, default: () => { return {} }, },
        items: Array,
        showIndex: { type: Boolean, default: false, },
    },
    emits: [
        'scrolled',
        'selected'
    ],
    data() {
        return {
            sortByField: undefined,
            sortOrder: 1,
            selected: [],
            search: '',
            emited: false,
            oldLength: 0,
        }
    },
    computed: {
        sortedItems() {
            let temp = this.items.slice().filter((e) => {
                if (this.search.length == 0) {
                    return true;
                }
                let boolArray = [];
                this.fields.forEach(element => {
                  boolArray.push( this.fieldsFormatter[element] == "date" ?
                this.format(e, element).toString().toLowerCase().includes(this.search.toLowerCase()) :
                 (e[element] !== null ? e[element].toString().toLowerCase().includes(this.search.toLowerCase()) : false)
                )

                });
                return boolArray.includes(true);
            })
            if (this.sortByField && this.sortOrder) {
                return temp.sort((left, right) => {
                    return (left === right ? 0 : left[this.sortByField] > right[this.sortByField] ? 1 : -1) * this.sortOrder
                })
            }
            return temp
        },
    },
    methods: {
        scroll(e) {
          let itemHeight  = e.target.scrollHeight/this.items.length
          if (e.target.scrollTop + e.target.offsetHeight + itemHeight  > e.target.scrollHeight && this.emited == false) {
              this.emited = true
              this.oldLength = e.target.scrollHeight
              this.$emit('scrolled')
            }
          if (e.target.scrollHeight !== this.oldLength){
            this.emited = false
          }
        },
        select(row) {
            const index = this.selected.indexOf(row)
            if (index === -1) {
                this.selected.push(row)
            } else {
                this.selected.splice(index, 1)
            }
            this.$emit('selected', this.selected)
        },
        format(item, fieldName) {
            // https://gist.github.com/jasonrhodes/2321581
            const value = fieldName.split('.').reduce((acc, part) => acc && acc[part], item)
            const valueFormater = this.fieldsFormatter[fieldName] !== undefined ? this.fieldsFormatter[fieldName] : ""

            if (valueFormater instanceof Function) {
                return valueFormater(value)
            } else {
                switch (valueFormater) {
                    case "bool":
                        if (typeof value == "boolean") {
                            return `<span class="w-3 h-3 rounded-full bg-${value ? 'primary' : 'transparent'} border border-${value ? 'primary' : 'default'}"></span>`
                        } break
                    case "date":
                        if (!isNaN(Date.parse(value))) {
                            const date = new Date(value)
                            return date.toLocaleDateString() + " " + date.toLocaleTimeString()
                        } break
                    case "file":
                        if (value) {
                            return `
                                    <a href="${value}" class="rounded shadow-sm focus:outline-none text-default px-2 py-1 text-xs bg-transparent border border-default cursor-pointer">
                                        Download Current
                                    </a>
                                `
                        } break
                    case "color":
                        if (value) {
                            return `<div class=" w-10 h-5 rounded-full" style="background-color:${value}"></div>`
                        } break
                    case "image":
                        if (value) {
                            return `
                                    <div class="w-full">
                                        <img class="ml-6 h-5 rounded-md" src="${value}" />
                                    </div>
                                `
                        } break
                    default: return value
                }
            }

        },
    }
}
</script>

<style scoped>
div {
    @apply flex max-h-full w-full max-w-full rounded-lg max-w-full my-1 p-0 pb-1 pr-1;
}
div .wrapper {
    @apply block w-full max-w-full rounded-lg shadow-md bg-body-accent overflow-scroll p-0 break-words;
    overflow-x: hidden;
    overflow-y: auto;
}
;
/* search{icon; loupe} */
.search {
    top: -4em;
    right: 14.5em;
    background-color: transparent;
    z-index: 50;
    width: 300px;
    height: 50px;
}
/*div for icon loupe and input "text" */
.icon {
    height: 40px;
    width: 40px;
    position: relative;
    align-items: flex-end;
    top: 5px;
    left: 80%;
}
/*icone loupe */
.loupe {
    width: 40px;
    height: 40px;
    right: 0%;
    position: absolute;
    top: 0px;
    left: 40%;
}
input[type="text"] {
    border: 1px solid transparent;
    position: absolute;
    outline: none;
    background-size: 25px;
    background-color: transparent;
    background-position: 100px;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    padding: 15px;
    transition: all 0s;
    right: 0%;
    cursor: pointer;
    top: -2.5px;
    color: transparent;
}
/*animate seach bar */
input[type="text"]:focus {
    width: 290px;
    max-width: 300px;
    border: 1px solid rgba(217, 217, 217, 1);
    color: #dbd91c;
    text-decoration: aliceblue;
    font-color: rgb(250, 248, 248);
    padding-left: 10px;
    position: absolute;
    background-color: transparent;
    right: -5%;
    top: -3px;
}
.wrapper {
    margin-top: -20px;
}
table {
    @apply table w-full max-w-full;
}
table:deep() thead {
    @apply min-w-full max-w-full sticky top-0 z-10;
}
table:deep() thead tr th.index {
    @apply font-bold w-10;
}
table:deep() thead tr th.additional {
    width: 1px;
    white-space: nowrap;
}
/* additional th fit content https://stackoverflow.com/questions/4757844/css-table-column-autowidth */
table:deep() thead tr th {
    @apply bg-body-accent px-5 py-4 text-left text-xs font-medium uppercase tracking-wider text-default;
    width: 1px;
    white-space: nowrap;
}
table:deep() thead tr th:first-child {
    @apply rounded-tl-lg;
}
table:deep() thead tr th:last-child {
    @apply rounded-tr-lg;
}
table:deep() thead tr th.sort {
    @apply cursor-pointer items-center;
}
table:deep() thead tr th.sort span {
    @apply relative inline-block align-middle;
}
table:deep() thead tr th.sort svg {
    @apply relative inline-block align-middle h-3 ml-4 mb-0 text-default;
}
table:deep() tbody {
    @apply max-w-full overflow-x-scroll;
}
table:deep() tbody tr {
    @apply bg-body border-l border-r border-body-accent;
}
table:deep() tbody tr:nth-of-type(2n+1) {
    @apply bg-body-accent2;
}
table:deep() tbody tr td {
    @apply px-5 py-2 text-sm text-default max-w-sm truncate;
}
table:deep() tbody tr td.index {
    @apply font-bold;
}
table:deep() tfoot {
    @apply sticky bottom-0;
}
table:deep() tfoot tr td {
    @apply bg-body-accent rounded-b-lg text-xs text-default px-5 py-1;
}
</style>
