<template>

    <div>
        <ul>
            <li v-for='(item, index) in this.items' :key='item.title'>
                <a :class="item.active ? 'current' : ''" @click="this.select(index, 0)">
                    <ic-icons class="icon" v-if="item.titleIcon" :name="item.titleIcon"></ic-icons>
                    <span class="title">{{ item.title }}</span>
                    <span class="badge" v-if="item.badge != undefined">{{ item.badge }}</span>
                </a>
                <ul v-if="item.active">
                    <li v-for='(item2, index2) in item.items' :key="item2.title">
                        <a :class="item2.active ? 'current': ''" @click="this.select(index, index2)">
                            <ic-icons class="icon" v-if="item2.titleIcon" :name="item2.titleIcon"></ic-icons>
                            <span class="title">{{ item2.title }}</span>
                            <span class="badge" v-if="item2.badge != undefined">{{ item2.badge }}</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
        <div class="content">
            <slot></slot>
        </div>
    </div>

</template>

<script>
    import { nextTick } from 'vue'
    export default {
        provide() { return {
            menu: this,
        }},
        data() { return {
            items: [],
        }},
        methods: {
            select(toIndex, toIndex2) {
                this.items.forEach((item, index) => {
                    item.active = toIndex === index
                    // SubMenuItem is wrapped into MenuItem slot wrapped in v-if active property
                    // If we don't execute that in next tick item.items is always empty because sub subchild component are not loaded.
                    nextTick(() => {
                        item.items.forEach((item2, index2) => {
                            item2.active = toIndex === index && toIndex2 === index2
                        })
                    })
                })
            }
        },
        mounted() {
            this.select(0, 0)
        },
    }
</script>

<style scoped>

    div                         { @apply flex h-full max-h-full w-full max-w-full; }

    /* nav  */
    div ul                      { @apply flex-none overflow-y-auto mr-2 my-1; }
    div ul li                   { @apply py-1; }
    div ul li a                 { @apply relative flex items-center justify-start py-3 px-5 font-medium text-sm rounded-lg cursor-pointer text-default; }
    div ul li a:hover           { @apply text-primary bg-body-accent2;  }
    div ul li a.current         { @apply text-primary cursor-default bg-body-accent; }
    div ul li a .title          { @apply ml-3; }
    div ul li a .badge          { @apply absolute -top-1 right-1 px-1.5 py-0.5 rounded-full text-xs font-light bg-danger text-white; }

    /* sub nav */
    div ul ul                   { @apply ml-5  mr-0;}

    div .content                { @apply flex-1 block h-full max-h-full w-full max-w-full overflow-hidden; }
    
</style>
