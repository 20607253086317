import cards            from './cards/cards.js'
import dropdowns        from './dropdowns/dropdowns.js'
import forms            from './forms/forms.js'
import icons            from './icons/icons.js'
import menus            from './menus/menus.js'
import modals           from './modals/modals.js'
import tables           from './tables/tables.js'
import notifs           from './notifs/notifs.js'
import miscs            from './miscs/miscs.js'

import Crud             from './Crud.vue'

export default {
    install: (app) => {

        cards.install(app)
        dropdowns.install(app)
        forms.install(app)
        icons.install(app)
        menus.install(app)
        modals.install(app)
        tables.install(app)
        notifs.install(app)
        miscs.install(app)

        app.component('ic-crud', Crud)

    }
}
