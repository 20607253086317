<template>

    <div class="flex items-center justify-between">
        <div class="flex items-center justify-start">
            <label class="rounded shadow-sm focus:outline-none text-default px-4 py-2 text-sm bg-transparent border border-default cursor-pointer">
                <span>Choose File</span>
                <input type="file" :accept="this.field.options.fileType" @change="change">
            </label>
        </div>
        <a v-if="this.current != undefined" :href="this.current">
            <ic-button size="sm">Download Current</ic-button>
        </a>
    </div>
    <p>
        <span class="text-xs text-info">
            Current: <span class="text-default">{{ this.value ? (this.value.name ? this.value.name : "no file selected") : "idfk" }}</span>
        </span>
    </p>
    <p>
        <small :class="{ error: this.error !== undefined }">{{ error }}</small>
    </p>
</template>

<script>
    // No mixin
    // We can't send this.value automaticaly
    // Instead we store it in new var current
    export default {
        props: {
            modelValue: { required: true },
            field: Object,
            error: String,
        },
        emits: [
            'update:modelValue'
        ],
        computed: {
            value: {
                get() { return this.modelValue },
                set(value) { this.$emit('update:modelValue', value) }
            },
        },
        data() { return {
            current: undefined,
        }},
        methods: {
            change(event) {
                const file = event.target.files[0]
                    // keep options field's meta-data (used afterwards during api call -> see API.js)
                    file.options = this.field.options
                this.value = file
            },
        },
        mounted() {
            this.current = this.field.value || this.modelValue
            // we tell API that file already existing : see create/update funcs in API.Js
            this.value = this.field.options.fileName
        }
    }
</script>

<style scoped>

    input { @apply !hidden; }
    input input[type="button"] { @apply bg-red-100; }

</style>
