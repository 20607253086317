<template>

    <router-view />

</template>

<script>
    import { createApp }                        from 'vue'
    import { createStore }                      from 'vuex'
    import { createRouter, createWebHistory }   from 'vue-router'

    import Particles                            from 'particles.vue3'

    import API                                  from './api/API.js'
    import AuthStore                            from './stores/AuthStore.js'
    import ThemeStore                           from './stores/ThemeStore.js'
    import components                           from './components/components.js'
    import views                                from './views/views.js'

    import version                              from './VERSION.js'

    export default {

        // MARK: Properties
        name: 'Name',

        version: version.iControlVersion,

        iconAnimated: require('@/iControl/assets/logo-icontrol.json'),

        namespaces: [],


        // MARK: Helpers
        get api() {
            if (window.location.port == "8081"){
                API.url = `http://${window.location.hostname}:8080/api/`
            } else {
                API.url = `https://${window.location.hostname}/api/`
            }
            return {
                install: (app) => {
                    app.config.globalProperties.$api = API
                }
            }
        },

        get vueStore() {
            let store = createStore({
                modules: {
                    auth: AuthStore,
                    theme: ThemeStore
                },
            })
            return store
        },

        get vueRouter() {
            var routes = [
                {
                    name: 'Dashboard', path: '/',
                    component: views.DashboardView,
                    props: { icontrol: this, }
                },
                {
                    name: 'Admin', path: '/admin',
                    component: views.AdminView,
                    props: { icontrol: this, }
                }
            ]
            this.namespaces.forEach(namespace => {
                namespace.icontrol = this
                routes.push({
                    name: namespace.name,
                    path: '/' + namespace.path,
                    component: views.DashboardNamespaceView,
                    props: { namespace: namespace },
                    meta: { name: namespace.name }
                })
                namespace.modules.forEach(module => {
                    module.namespace = namespace
                    routes.push({
                        name: namespace.name + ' ' + module.name,
                        path: '/' + namespace.path + '/' + module.path,
                        component: module.component,
                        props: { module: module, ...module.componentProps },
                        meta: { name: module.name },
                    })
                })
            })
            let router = createRouter({
                history: createWebHistory(),
                routes: [
                    {
                        name: 'iControl', path: '/',
                        component: views.Templates.Base,
                        props: { iControl: this },
                        children: routes
                    },
                    {
                        name: 'Login', path: '/login',
                        component: views.Templates.Auth,
                        props: { iControl: this, page: 'login' },
                    },
                    {
                        name: 'Register', path: '/register',
                        component: views.Templates.Auth,
                        props: { iControl: this, page: 'register' },
                    },
                    {
                        name: 'Activate', path: '/activate',
                        component: views.Templates.Auth,
                        props: { iControl: this, page: 'activate' },
                    },
                    {
                        name: 'Activated', path: '/activated',
                        component: views.Templates.Auth,
                        props: { iControl: this, page: 'activated' },
                    },
                    {
                        name: 'ForgottenPassword', path: '/forgotten-password',
                        component: views.Templates.Auth,
                        props: { iControl: this, page: 'forgotten-password' },
                    },
                    {
                        name: 'ForgottenPasswordProcessing', path: '/forgotten-password-processing',
                        component: views.Templates.Auth,
                        props: { iControl: this, page: 'forgotten-password-processing' },
                    },
                    {
                        name: 'RecoverPassword', path: '/recover-password',
                        component: views.Templates.Auth,
                        props: { iControl: this, page: 'recover-password' },
                    },
                    {
                        name: 'RecoveredPassword', path: '/recovered-password',
                        component: views.Templates.Auth,
                        props: { iControl: this, page: 'recovered-password' },
                    },
                    {
                        name: 'CancelDelete', path: '/cancel-delete',
                        component: views.Templates.Auth,
                        props: { iControl: this, page: 'cancel-delete' },
                    },
                    {
                        name: 'CanceledDelete', path: '/canceled-delete',
                        component: views.Templates.Auth,
                        props: { iControl: this, page: 'canceled-delete' },
                    },
                    {
                        name: 'NotFound', path: '/:pathMatch(.*)',
                        component: views.Templates.NotFound,
                        props: { iControl: this },
                    },
                ]
            })
            return router
        },
        // MARK: - Start
        start() {
            var api = this.api
            var store = this.vueStore
            var router = this.vueRouter
                router.beforeEach((to, from, next) => { // auth middleware
                    if (to.matched.some(record => record.name == "iControl")) { // unauthed user on requiring auth view redirect to /login
                        if (store.getters['auth/isAuthenticated']) {
                            next()
                        } else {
                            store.dispatch('auth/refresh')
                                .then(() => next())
                                .catch((error) => { console.log(error); return next('/login') })
                        }
                    } else {
                        next()
                    }
                })
                router.afterEach((to) => {
                    document.title = this.name + ' - ' + to.name
                })
            let clickOutsideDirective = {
                beforeMount: (el, binding) => {
                    el.clickOutsideEvent = event => {
                        // here I check that click was outside the el and his children
                        if (!(el == event.target || el.contains(event.target))) {
                            // and if it did, call method provided in attribute value
                            binding.value();
                        }
                    };
                    document.addEventListener("click", el.clickOutsideEvent);
                },
                unmounted: el => {
                    document.removeEventListener("click", el.clickOutsideEvent);
                },
            };
            
            let app = createApp(this)
                .directive("click-outside", clickOutsideDirective)
                .use(api)
                .use(store)
                .use(router)
                .use(Particles)
                .use(components)
            var vm = app.mount('#app')
                vm.$store.dispatch('theme/init')

        },

    }
</script>

<style>

    @tailwind base;
    @tailwind components;
    @tailwind utilities;
    @tailwind variants;
    @tailwind forms;

    html { @apply overflow-hidden; }

    .ic-liquid::-webkit-scrollbar { @apply hidden; }
    
    .ic-container { @apply relative h-screen max-h-screen w-screen max-w-full; }

    .ic-liquid { @apply relative h-full max-h-full w-full max-w-full px-10; scrollbar-width: none;}

    .light {

        --color-default: rgba(87, 87, 87, 1);
        --color-primary: rgba(220, 219, 15, 1);
		--color-success: rgba(220, 219, 15, 1);
		--color-warning: rgba(242, 145, 52, 1);
		--color-danger: rgba(231, 54, 96, 1);
        --color-info: rgba(134, 134, 134, 1);

        --color-header: rgba(255, 255, 255, 1);
		--color-body: rgba(240, 241, 243, 1);
        --color-body-accent: rgba(255, 255, 255, 1);
        --color-body-accent2: rgba(248, 248, 248, 1);

    }

    .dark {

        --color-default: rgba(217, 217, 217, 1);
		--color-primary: rgba(220, 219, 15, 1);
		--color-success: rgba(220, 219, 15, 1);
		--color-warning: rgba(242, 145, 52, 1);
		--color-danger: rgba(231, 54, 96, 1);
        --color-info: rgba(134, 134, 134, 1);

        --color-header: rgba(63, 63, 63, 1);
		--color-body: rgba(77, 77, 77, 1);
        --color-body-accent: rgba(61, 61, 61, 1);
        --color-body-accent2: rgba(85, 85, 85, 1);

	}

</style>
