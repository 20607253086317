export default {

    namespaced: true,

    state: {
        theme: undefined
    },

    getters: {

        current: state => state.theme

    },

    actions: {

        init({ commit }) {
            const stored = window.localStorage.theme ? localStorage.theme : false
            const prefers = window.matchMedia('(prefers-color-scheme: dark)').matches
            const choosed = stored ? stored : prefers ? 'dark' : 'light'
            document.querySelector('html').classList.add(choosed)
            commit('set', choosed)
        },

        toggle({ commit }) {
            const stored = window.localStorage.theme
            switch (stored) {
                case 'light':
                    document.querySelector('html').classList.remove('light')
                    document.querySelector('html').classList.add('dark')
                    commit('set', 'dark')
                    break
                case 'dark':
                    document.querySelector('html').classList.remove('dark')
                    document.querySelector('html').classList.add('light')
                    commit('set', 'light')
                    break
                default:
                    document.querySelector('html').classList.remove('dark')
                    document.querySelector('html').classList.add('light')
                    commit('set', 'light')
                    break
            }
        }

    },

    mutations: {

        set(state, theme) {
            state.theme = theme
            localStorage.theme = theme
        }

    }

}
