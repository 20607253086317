<template>
    <div class="notifications">
        <transition-group name="group">
            <div class="notification" :class="variants(notification)" v-for="notification in notifications" :key="notification.text" @click="remove(notification)">
                <ic-icons :class="variantsIcon(notification)" :name="icons(notification)"></ic-icons>
                <span class="pl-2">{{ notification.text }}</span>
            </div>
        </transition-group>
    </div>
</template>

<script>
    const variants = {
        'default'   : 'border border-info text-default',
        'primary'   : 'border border-primary text-default',
        'success'   : 'border border-success text-default',
        'info'      : 'border border-info text-default',
        'warning'   : 'border border-warning text-default',
        'danger'    : 'border border-danger text-default',
    }
    const variantsIcon = {
        'default'   : 'text-default',
        'primary'   : 'text-primary',
        'success'   : 'text-success',
        'info'      : 'text-info',
        'warning'   : 'text-warning',
        'danger'    : 'text-danger',
    }
    const icons = {
        'default'   : 'InformationCircle',
        'primary'   : 'CheckCircle',
        'success'   : 'CheckCircle',
        'info'      : 'InformationCircle',
        'warning'   : 'Exclamation',
        'danger'    : 'ExclamationCircle',
    }

    export default {
        props: {
            duration: { type: Number, default: 5000, }
        },
        data() { return {
            notifications: []
        }},
        methods: {
            variants(notification) {
                return [ variants[notification.variant] || variants.default ]
            },
            variantsIcon(notification) {
                return [ variantsIcon[notification.variant] || variantsIcon.default ]
            },
            icons(notification) {
                return icons[notification.variant] || icons.default
            },
            notify(notification) {
                this.notifications.unshift(notification)
                setTimeout(() => this.remove(notification), this.duration)
            },
            remove(notification) {
                const index = this.notifications.indexOf(notification)
                if (index !== -1) {
                    this.notifications.splice(index, 1)
                }
            }
        },
        mounted() {
            this.$notifier.on('notify', this.notify)
        },
        unmounted() {
            this.$notifier.off('notify')
        }
    }
</script>

<style scoped>

    .notifications                      { @apply block space-y-3; }

    .notifications .notification        { @apply flex items-center bg-header justify-start whitespace-nowrap rounded-lg px-6 py-1 m-2.5 cursor-pointer min-w-min; }
    .notifications .notification .icon  { @apply h-5 mr-3; }

    .group-move {
        transition: all 0.5s ease;
    }

    .group-enter-active,
    .group-leave-active {
        transition: all 0.5s ease;
    }

    .group-enter-from,
    .group-leave-to {
        @apply opacity-0
    }

    .group-leave-from,
    .group-enter-to {
        @apply opacity-100
    }

</style>
