<template>

    <button type="button" :class="classes">
        <div class="flex justify-center items-center">
            <slot></slot>
        </div>
    </button>

</template>

<script>
    const generics = {
        "all": "rounded focus:outline-none m-1",
    }
    const sizes = {
        "xs": "px-1 py-0.5 text-xs",
        "sm": "px-4 py-2 text-sm",
        ""  : "px-8 py-4 text",
    }
    const variants = {
        "default"   : "text-default bg-transparent border border-default",
        "primary"   : "text-white bg-primary",
        "success"   : "text-white bg-success",
        "warning"   : "text-white bg-warning",
        "danger"    : "text-white bg-danger",
        "info"      : "text-white bg-info",
    }
    const variantsOutline = {
        "default"   : "text-default bg-transparent hover:ring-1 hover:ring-default",
        "primary"   : "text-primary bg-transparent hover:ring-1 hover:ring-primary",
        "success"   : "text-success bg-transparent hover:ring-1 hover:ring-success",
        "warning"   : "text-warning bg-transparent hover:ring-1 hover:ring-warning",
        "danger"    : "text-danger bg-transparent hover:ring-1 hover:ring-danger",
        "info"      : "text-info bg-transparent hover:ring-1 hover:ring-info",
    }
    export default {
        props: {
            size: { type: String, default: '' },
            variant: { type: String, default: 'default' },
            outline: { type: Boolean },
        },
        computed: {
            classes() {
                return [ generics['all'], sizes[this.size], this.outline ? variantsOutline[this.variant] : variants[this.variant] ]
            }
        }
    }
</script>
