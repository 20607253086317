import Menu         from './Menu.vue'
import MenuItem     from './MenuItem.vue'
import MenuSubItem  from './MenuSubItem.vue'

export default {
    install: (app) => {

        app.component('ic-menu', Menu)
        app.component('ic-menu-item', MenuItem)
        app.component('ic-menu-subitem', MenuSubItem)

    }
}
