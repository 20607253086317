<template lang="html">
    <div class="relative pt-1">
      <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
        <div :style="{ width: progress + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary"></div>
      </div>
    </div>
</template>

<script>
export default {
    props: {
        progress: { type: Number, default: 50 }
    }
}
</script>
