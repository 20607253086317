<script>
    export default {
        props: {
            modelValue: { required: true },
            field: { type: Object, required: true },
            error: { type: String, default: undefined },
        },
        emits: [
            'update:modelValue'
        ],
        computed: {
            value: {
                get() { return this.modelValue },
                set(value) { this.$emit('update:modelValue', value) }
            },
        },
        mounted() {
            this.value = this.field.value || this.modelValue
        }
    }
</script>
